import { Select, Divider, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Component } from 'react';

const { Option } = Select;



class MultiTaskingComboBox extends Component {
  state = {
    items: [],
    name: '',
  };

  componentDidMount(){
    console.log(this.props.data); 
    this.setState({
      items: this.props.data
    })
  }

  onNameChange = event => {
    this.setState({
      name: event.target.value,
    });
  };

  addItem = () => {
    
    const { items, name } = this.state;
    this.setState({
      items: [...items, name ],
      name: '',
    });
  };

  render() {
    const { items, name } = this.state;
    return (
      <Select
        showSearch
        placeholder={this.props.placeHolder}
        className='w-100 pb-1 allTextInput'
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} />
              <Button
                onClick={this.addItem}>
                <PlusOutlined /> {this.props.ListTitle}
              </Button>
            </div>
          </div>
        )}
      >
        {items.map(item => (
          <Option key={item}>{item}</Option>
        ))}
      </Select>
    );
  }
}

export default MultiTaskingComboBox;