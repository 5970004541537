import { List, Empty  } from 'antd';

function GlobalSearch() {
  return (
    <div >
        <List.Item>
            <Empty/>
        </List.Item>
    </div>
  );
}

export default GlobalSearch;


