
import {Affix, Divider, Button } from 'antd';
import { List , Avatar } from 'antd';
import { useState } from 'react';
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer';



export default function  NewNotifications(props: any): any {
    let [visibility, setVisibility] = useState<boolean>(false);
    const showDrawer = () => {
        setVisibility(true);
      };
    const onClose = () => {
        setVisibility(false);
      };

    return(
        <div style={{width: '400px', height: '400px', overflowY: 'scroll',overflowX: 'hidden'}}>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design"> New deposit</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design"> New Order Receive</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design"> Gradient Margin Found</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design"> Less Ability Found </a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="https://ant.design"> New retriving Information Error</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
            <Affix offsetBottom={10}>    
                <Divider type='horizontal' >...</Divider>
                <Button type="link" onClick={showDrawer} block>
                   View More
                </Button>
                <NotificationDrawer visibility={visibility} handleVisibility={onClose} />
            </Affix>
            
        </div>
    )
}