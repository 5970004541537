
import { Badge, Collapse } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Notifications from '../NotificationDrawer/Notifications/Notifications';

const { Panel } = Collapse;

function callback(key: any) {
  console.log(key);
}



const genExtra = () => (
  <SettingOutlined
    onClick={event => {
      event.stopPropagation();
    }}
  />
);

function CategoryNotification():any {
  
    return (
      <>
        <Collapse
          onChange={callback}
          expandIconPosition='right'
        >
          <Panel header={<><Badge color='red'/><span>Admission Information</span></> } key="1" extra={genExtra()}>
                  <Notifications />
          </Panel>
          <Panel header={<><Badge color='green'/><span>event Information</span></> } key="2" extra={genExtra()}>
            <Notifications />
          </Panel>
          <Panel header={<><Badge color='blue'/><span>Teacher Request</span></> } key="3" extra={genExtra()}>
            <Notifications />
          </Panel>
          <Panel header={<><Badge color='red'/><span>Fee Remaining </span></> } key="4" extra={genExtra()}>
            <Notifications />
          </Panel>
          <Panel header={<><Badge color='yellow'/><span>Warning about miss lead activity</span></> } key="5" extra={genExtra()}>
            <Notifications />
          </Panel>
          <Panel header={<><Badge color='green'/><span>New Incoming Events List and participate</span></> } key="6" extra={genExtra()}>
            <Notifications />
          </Panel>
        </Collapse>
      </>
    );
}

export default CategoryNotification;