import React from 'react';
import { Table } from 'antd';


const data = [
  {
    key: '1',
    id: 528885,
    name: 'Umer',
    class: '4th',
  },
  {
    key: '2',
    id: 598885,
    name: 'Irfan',
    class: '6TH',
  },
  {
    key: '3',
    id: 528985,
    name: 'Ali',
    class: 'A-L',
  },

];

class Siblings extends React.Component {
  render() {
    const columns = [
      {
        title: 'S. ID',
        dataIndex: 'id',
        key: 'id',
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
      },
      {
        title: 'Class',
        dataIndex: 'class',
        key: 'class',
        sorter: (a, b) => a.class.length - b.class.length,
        sortDirections: ['descend', 'ascend'],
      }
    ];
    return <Table columns={columns} dataSource={data} />;
  }
}


export default Siblings;