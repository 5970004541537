import { Typography, Form, message,  Radio, Input } from "antd";
import {  MailOutlined, IdcardOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";

const { TextArea } = Input;
const { Text, Title } = Typography;





export default function PersonalForm(props: any) {
    const [form] = Form.useForm();

    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    // const onFill = () => {
    //     form.setFieldsValue({
    //         url: 'https://taobao.com/',
    //     });
    // };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <div className="container-fluid p-4" style={{backgroundColor: 'white'}}>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Registration Number"
                            label="Registration Number"
                            rules={[{ required: true },  { type: 'string', min: 6, warningOnly: true }]}>
                            <Title level={4}>
                                <Text code>Fall-2022-001</Text>
                            </Title>
                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 2
                }
                <div className="row" >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Student First Name"
                            label="Student First Name"
                            rules={[{ required: true }, { type: 'string', min: 6 }]}
                        >
                            <Input className="allTextInput"  placeholder="First Name" suffix={"FN"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Student Last Name"
                            label="Student Last Name"
                            rules={[{ type: 'string', min: 4, warningOnly: true }]}
                        >
                            <Input  className="allTextInput"  placeholder="Last Name" suffix={'LN'} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                    <Form.Item
                            name="Student Email"
                            label="Student @Email"
                            rules={[ { type: 'email', min: 20,max: 100, warningOnly: true }]} >
                            <Input className="allTextInput" placeholder="Email" suffix={<MailOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-11 col-11 m-2">
                        <Form.Item
                            name="Gender"
                            label="Select Gender"
                            rules={[{ required: true }, { type: 'number'}]}>
                            <Radio.Group name="Gender" defaultValue={1} className="pt-2" style={{borderRadius: '5px',boxShadow: '5px 5px 2px #e5e2e2'}}>
                                <Radio.Button value={1}>Male</Radio.Button>
                                <Radio.Button value={2}>Female</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 3
                }
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Identity Number"
                            label={<>Student CNIC / B-Form &nbsp;<IdcardOutlined/></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                             <NumberFormat placeholder="National Identity" className="form-control"
                                    format="#####-#######-#"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12 col-12 m-2" >
                        <TextArea  placeholder="Add Details About this New admission for example any history " className="w-100 m-4 allTextInput" rows={4} />
                    </div>
                </div>
            </div>
        </Form>
    )

}