import {Row, Col } from 'antd';
import React from 'react';
import 'antd/dist/antd.css';
import { Steps, Button, message } from 'antd';
import PersonalForm from '../Forms/PersonalForm';
import AcadamicForm from '../Forms/AcadamicForm';
import ParentalForm from '../Forms/ParentalForm';
import FeesForm from '../../SharedComponents/Forms/FeesFrom';
import Attachments from '../Forms/Attachments';
import View from '../Forms/View';
const { Step } = Steps;

const steps = [
  {
    title: 'Personal',
    content: <PersonalForm/>,
  },
  {
    title: 'Parental',
    content: <ParentalForm/>,
  },
  {
    title: 'Acadamic',
    content: <AcadamicForm/>,
  },
  {
    title: 'Fees',
    content: <FeesForm/>,
  },
  {
    title: 'Attachments',
    content: <Attachments/>,
  },
  {
    title: 'View',
    content: <View/>,
  }
];

const AdmissionFormSteps = () => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <h4 className='h4 text-center mb-4'>Registration Form</h4>
      <Row >
        <Col span={20} offset={2} className='p-4 shadow'>
          <Steps size="small" current={current}>
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </Col>
      </Row>
      
      <div className="steps-content mt-4">
        {steps[current].content}
      </div>
      <Row justify='end' className="steps-action m-2">
        {current < steps.length - 1 && (
          <Button className='m-1' style={{borderRadius: '5px'}} type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button className='m-1' style={{borderRadius: '5px'}} type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button className='m-1' style={{borderRadius: '5px'}} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </Row>
      
    </>
  );
};

export default AdmissionFormSteps;