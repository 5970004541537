
import  { useCallback } from 'react';
import { Modal } from 'antd';
import ImageProcessing from './ImageProcessing';

const ImageProcessingModal = ({ ProfileImage, visibility, setVisibility} :any) => {
    
    const updateSrcImg = useCallback((img)=>{
      ProfileImage(img)
      alert('image Received')
    }, [ProfileImage])
    
    return (
            <Modal
              title="Image Croping"
              centered
              visible={visibility}
              onOk={()=>setVisibility(false)}
              width={'80%'}>
              <ImageProcessing receiveCapturedImage={updateSrcImg} />
            </Modal>
      );
}

export default ImageProcessingModal;