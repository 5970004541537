import CaptureImage from "./CaptureImage";
import  { useState } from 'react';
import { Modal, Button } from 'antd';

const CameraModal = ({receiveCapturedImage ,name}:any) => {
    const [visible, setVisible] = useState(false);


    return (
        <>
          <Button type="primary" onClick={() => setVisible(true)} className='ml-1 allTextInput'>
            {name ? name :'Camera'}
          </Button>
            <Modal
              title="Camera"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              width={'80%'}
            >
              <CaptureImage receiveCapturedImage={receiveCapturedImage} />
          </Modal>
        </>
      );
}

export default CameraModal;



  
