
import { Modal } from 'antd';
import RegisteredAdmissionList from '../../RegisteredAdmissionsList/RegisteredAdmissionList';

export default function RegisteredAdmission(props: any){
    return (
         <Modal
                title="Search and Select Registered Student"
                centered
                visible={props.visible}
                onOk={() => props.setVisible(false)}
                onCancel={() => props.setVisible(false)}
                width={1360}>
                <RegisteredAdmissionList selectionAllow={true} headerAllow={false}/>
        </Modal>
    )

}