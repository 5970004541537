import { message,  Input } from "antd";
import { Typography, Form } from "antd";
import {  MedicineBoxOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";


const { TextArea } = Input;
const { Text, Title } = Typography;


export default function MadicalForm(props: any) {
    const [form] = Form.useForm();

    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <div className="container-fluid p-4 m-1 workingArea" >
                {
                    // Row 1 start
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Student ID"
                            label="Student ID"
                            rules={[{ required: true }, { type: 'string', warningOnly: true }]}>
                            <Title level={4}><Text code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Registration ID"
                            label="Registration ID"
                            rules={[{ required: true }, { type: 'string', warningOnly: true }]}>
                            <Title level={4}><Text code>R-Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 1 Closed
                    //Row 2 Start
                }
                <div className="row" >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Disease Name"
                            label="Any Long Term Diseases"
                            rules={[{ required: false }, { type: 'string', min: 6 }]}>
                            <Input className="allTextInput" placeholder="Disease Name" suffix={<MedicineBoxOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Madical Condition"
                            label="Enter Madical Condition"
                            rules={[{ type: 'string', min: 4, warningOnly: true }]}
                        >
                            <Input className="allTextInput" placeholder="Madical Condition" suffix={<MedicineBoxOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Emergency Number"
                            label="Emergency Contact Number"
                            rules={[{ type: 'string', min: 7, max: 20, warningOnly: true }]} >
                            <NumberFormat placeholder="Medical Number" className="form-control allTextInput" format="+923##-#######"/>
                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 3
                }
                <div className="row">
                    <Form.Item
                        name="Madical History"
                        label="Madical History Details"
                        className="w-100 ml-4 mr-4"
                        rules={[{ required: false }, { type: 'string' }]}>
                        <TextArea placeholder="Add medical history and more details " className="allTextInput" rows={4} />
                    </Form.Item>
                </div>
            </div>
        </Form>
    )

}