
import { Tooltip, Button, Popover } from "antd";
import { useState } from "react";
import { useFileUpload } from "use-file-upload";
import { UploadOutlined } from '@ant-design/icons';


const FileUpload = ({ currentUploadName, srcImage }: any) => {

  

  const [fileName, setFileName] = useState<string>();
  const [fileSource, setFileSource] = useState<string>();
  const [fileSize, setFileSize] = useState<number>();
  const [, selectFiles] = useFileUpload();

  const contantPopOver = <div className="container">
                            <img src={fileSource && fileSource} alt='No' width={100} height={100}/>
                          </div>

  const upload = () => {
    selectFiles({ accept: "image/*", multiple: false }, ({ name, source, size }: any) => {
      setFileName(name);
      setFileSource(source);
      setFileSize(size);
      srcImage(source)
    })
  }

  return (
    <div className="row m-3">
      <div className="col p-1">
        <Button type="primary" onClick={upload} icon={<UploadOutlined />} style={{borderRadius: '5px'}}>
          {currentUploadName}
        </Button>
        <Tooltip title={fileSize ? (fileSize / 1024).toFixed(0) + 'KB' : ''}><strong className="ml-3">{fileName && fileName}</strong></Tooltip>
        <Popover content={contantPopOver} title={currentUploadName}>
          {fileSource && <a rel="noreferrer" href={fileSource} target='_blank' className="ml-3"> <img src={fileSource && fileSource} alt='No' width={30} height={20}/></a>}
        </Popover>
      </div>
    </div>
  );
};


export default FileUpload;
