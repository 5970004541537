import { Menu, Dropdown } from "antd";
import {
  KeyOutlined,
  UserAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import AvatarTopbar from "../../Sidebar/UIElements/Avatar";
import { useCallback, useState } from "react";
import { connect } from "react-redux";

function ProfileDropDown(props: any): any {
  const [isProfileDDVisible, setProfileDDVisible] = useState(false);
  const ProfileDDVisibilityHandler = useCallback(() => {
    setProfileDDVisible(!setProfileDDVisible);
  }, [isProfileDDVisible]);

  return (
    <Dropdown overlay={menu}>
      <div style={{ color: props.themeState.color, marginBottom: "20px" }}>
        <a href="/">
          <AvatarTopbar />
          {/* <span style={{paddingLeft: '10px',color: props.themeState.color}}>Matrica</span> */}
        </a>
      </div>
    </Dropdown>
  );
}

const menu = (
  <Menu theme="light">
    <Menu.Item icon={<UserAddOutlined />}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.GOOGLE.com"
      >
        Profile
      </a>
    </Menu.Item>
    <Menu.Item icon={<KeyOutlined />}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.GOOGLE.com"
      >
        Change Password
      </a>
    </Menu.Item>
    <hr />
    <Menu.Item icon={<LogoutOutlined />}>Logout</Menu.Item>
  </Menu>
);

const mapStatetoProps = (state: any): any => {
  return {
    themeState: state,
  };
};

export default connect(mapStatetoProps)(ProfileDropDown);
