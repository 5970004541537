import { useEffect } from "react";
import RegistrationSteps from "./RegistrationSteps/RegistrationSteps";



export default function NewRegistration(props: any){
    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
          window.removeEventListener('beforeunload', alertUser)
        }
      }, [])
      const alertUser = (e:any) => {
        e.preventDefault()
        e.returnValue = ''
      }
    return (
        <>
            
            <RegistrationSteps />
        </>
        
    )

}