import {Select, Typography, Form, message } from "antd";
import { useState } from "react";
import YearlyFeePlan from "../Tables/YearlyFeePlanTable";


const { Text, Title } = Typography;
const { Option } = Select;


export default function FeesForm(props: any){

    const [fromOf,setFormOf]=useState(null);
    useState(()=>{
        if(fromOf === null)
            setFormOf(props.formOf)
    })

    const onFinish = () => {
        message.success('Submit success!');
    };

    function onChange(value: any) {
        
    }
      
    function onSearch(val: any) {
    
    }
    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    const [form] = Form.useForm();

    console.log(fromOf);
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off" >
            <div className="container-fluid" style={{backgroundColor: 'white'}}>
                {
                    // Row 1 
                }
                <div className="row p-4">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12" >
                        <Form.Item
                                name="Registration ID"
                                label="Registration ID"
                                rules={[{ required: true },  { type: 'string', min: 6, warningOnly: true }]}>
                                <Title level={4}>
                                    <Text code>Fall-2022-001</Text>
                                </Title>
                        </Form.Item>
                    </div>
                    {fromOf === 'admission' && 
                        <div className="col-md-3 col-sm-8">
                            <Form.Item
                                name="Registration ID"
                                label="Registration ID"
                                rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                                <Title level={4}><Text code>R-Fall-2022-001</Text></Title>
                            </Form.Item>
                        </div>}
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12" >
                        <Form.Item
                            name="Select Fee Plan"
                            label="Select Fee Plan"
                            rules={[{ required: true },  { type: 'string', warningOnly: true }]}>
                            <Select
                                showSearch
                                className="allTextInput"
                                placeholder="Select Fee Plan"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                <Option value="1">Regular Yealry Plan</Option>
                                <Option value="2">From Nov 2019 to Aug 2020 Liberty Campus</Option>
                                <Option value="3">Regular Fee Plan 2020-2021</Option>
                                <Option value="4">Regular Yealry Plan</Option>
                                <Option value="5">Regular Yealry Plan</Option>
                                <Option value="6">Regular Yealry Plan</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12" >
                        <YearlyFeePlan />
                    </div>
                </div>
            </div>
        </Form>
    )

}