
import AdmissionFormSteps from './AdmissionFormSteps/AdmissionFormSteps';
import { createBrowserHistory } from "history";
export default function NewAdmission(props: any){
    
    return (
        <>
        
            <AdmissionFormSteps/>
        </>
    )

}