
import { SWITCH_THEME } from "../Actions/Actions";

const initState = {
        theme: 'light',
        color: 'black',
        backcolor: 'white'
};


const ThemeReducer = (state = initState,  action: any ): any=>{
        
        if(action.type === SWITCH_THEME) {
                if(action.payload === 'light')
                return {...state,
                    theme: 'dark',
                    color: 'white',
                    backcolor: '#001529'};  
                else
                    return {state,
                                theme: 'light',
                                color: 'black',
                                backcolor: 'white'};
        }
        
        return state;
}

export default ThemeReducer;