import { Collapse, message, Select, Button, Space, Modal, Divider } from "antd";
import { Typography, Form } from "antd";
import { HomeOutlined } from '@ant-design/icons';
import { useState } from "react";
import GroupCheckBox from "../../../../Components/MainComponents/GlobalCustomUI/GroupCheckBox/GroupCheckBox";
import { ClassPlanData } from "../../../../StaticDataSource/StudentModule/ClassPan";



const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

export default function AcadamicForm(props: any) {
    const [form] = Form.useForm();
    const [visibilityHouseModel, setVisibilityHouseModel] = useState(false);

    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <div className="container-fluid p-4 m-1 workingArea" >
                {
                    // Row 1 start
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Student ID"
                            label="Student ID">
                            <Title level={4}><Text code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Registration ID"
                            label="Registration ID">
                            <Title level={4}><Text code>R-Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Space align="center" size='small' direction="horizontal">
                            <Form.Item
                                name="House"
                                label="House"
                                rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                                <Select placeholder="Select a house" className="allTextInput"  >
                                    <Option value={1}>Falcon</Option>
                                    <Option value={2}>ABC</Option>
                                </Select>
                            </Form.Item>
                            <Button className="mt-2" type="primary" onClick={() => { setVisibilityHouseModel(!visibilityHouseModel) }} shape="circle" icon={<HomeOutlined />} />
                            <Modal onOk={() => setVisibilityHouseModel(!visibilityHouseModel)} onCancel={() => setVisibilityHouseModel(!visibilityHouseModel)} title='List of House above counting' visible={visibilityHouseModel} />
                        </Space>
                    </div>
                </div>
                {
                    //Row 1 Closed
                    //Row 2 Start
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8">
                        <Form.Item
                            name="Class Plan"
                            label="Class Plan"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select placeholder="Select class plan" className="allTextInput"  >
                                <Option value={1}>Play Group (Red)</Option>
                                <Option value={2}>Play Group (Blue)</Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <Divider type="horizontal"> {ClassPlanData.name} </Divider>
                <Collapse className="m-2">
                    <Panel header={<><span className="white">{ClassPlanData.terms[0].termName}</span></>} key="1" className="emphasisColor">
                        <div className="row border p-2 m-1">
                            <div className="col-md-6 col-sm-8">
                                <GroupCheckBox title='Compulsory Subjects' defaultCheckedList={ClassPlanData.terms[0].CompulsorySubjects.defaultSubjects} plainOptions={ClassPlanData.terms[0].CompulsorySubjects.subjects} />
                            </div>
                            <div className="col-md-6 col-sm-8">
                                <GroupCheckBox title='Elective Subjects' defaultCheckedList={ClassPlanData.terms[0].Elective.defaultSubjects} plainOptions={ClassPlanData.terms[0].Elective.subjects} />
                            </div>

                        </div>
                    </Panel>
                    <Panel className="emphasisColor" header={<><span className="white">{ClassPlanData.terms[1].termName}</span></>} key="2">
                        <div className="row border p-2 m-1">
                            <div className="col-md-6 col-sm-8">
                                <GroupCheckBox title='Compulsory Subjects' defaultCheckedList={ClassPlanData.terms[1].CompulsorySubjects.defaultSubjects} plainOptions={ClassPlanData.terms[1].CompulsorySubjects.subjects} />
                            </div>
                            <div className="col-md-6 col-sm-8">
                                <GroupCheckBox title='Elective Subjects' defaultCheckedList={ClassPlanData.terms[1].Elective.defaultSubjects} plainOptions={ClassPlanData.terms[1].Elective.subjects} />
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </div>
        </Form>
    )

}