

import ProcessImage from "react-imgpro";

const AddEffect = ({sourceImage, outputImage, brightness, contrast})=>{
    
    return(
        <ProcessImage
          image={sourceImage}
          resize={{ width: 300, height: 200, mode: 'bilinear' }}
          brightness={brightness / 10}
          contrast={contrast / 10 }
          quality={100}
          storage={true}
          processedImage={outputImage} />    
    );
    
}

export default AddEffect;