import { Tabs } from 'antd';
import { Component } from 'react';
import FeeHead from './FeeHeads/FeeHeads';
import CreateFeeTamplate from './FeeTemplates/CreateFeeTamplates/CreateFeeTamplates';
import FeeTamplatesList from './FeeTemplates/ListFeeTamplates/FeeTamplatesList';
import './styles.css'
const { TabPane } = Tabs;

class Fee extends Component {
  

  render() {
    
    return (
      <>
        <Tabs tabBarGutter={0} type='card' animated={{inkBar: true, tabPane: true}} tabPosition='left'>
          <TabPane tab="Fee Head" key="1">
            <FeeHead/>
          </TabPane>
          <TabPane tab="Create Template" key="2">
            <CreateFeeTamplate/>
          </TabPane>
          <TabPane tab="Template List" key="3">
            <FeeTamplatesList/>
          </TabPane>
          <TabPane tab="Yearly Fee Plan" key="4">
            Yearly Fee Plan Under Construction
          </TabPane>
          <TabPane tab="Fee Modification" key="5">
            Fee Modification Under Construction
          </TabPane>
          <TabPane tab="Continue" key="6">
            ...
          </TabPane>
        </Tabs>
      </>
    );
  }
}


export default Fee;