import React, { useState, useEffect, useRef, createRef } from "react";
import "antd/dist/antd.css";
import {
  message,
  Tree,
  Button,
  Modal,
  Form,
  DatePicker,
  Switch,
  Input,
  Upload,
  Tooltip,
  Dropdown,
  Menu,
  Popconfirm,
  Checkbox,
  Image,
} from "antd";
import { AddFolder, AddFile, ViewFileShow } from "../DataInterfaces";
import axios from "axios";
import {
  FileTwoTone,
  DownloadOutlined,
  UsergroupAddOutlined,
  FolderTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  FilePdfTwoTone,
  FileWordTwoTone,
  FileExcelTwoTone,
  FilePptTwoTone,
  FileZipTwoTone,
  FileImageTwoTone,
  VideoCameraTwoTone,
} from "@ant-design/icons";

import { ImageViewer } from "react-image-viewer-dv";

var moment = require("moment");
const FolderSide = (props) => {
  const [selectedNode, setSelectedNode] = useState("");
  const [selectedNodeTitle, setSelectedNodeTitle] = useState("");
  const [defaultSelected, setDefaultSelected] = useState("ChildLeaf2");
  const [TreeDataSave, setTreeDataSave] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [folderType, setFolderType] = useState(0);
  const [visibleAddFolder, setVisibleAddFolder] = useState(false);
  const [visibleAddFile, setVisibleAddFile] = useState(false);
  const [addUserAccess, setAddUserAccess] = useState(false);
  const [visibleViewFile, setVisibleViewFile] = useState([]);

  const [folderName, setFolderName] = useState("");
  const [fileSource, setFileSource] = useState();
  const [filesList, setFilesList] = useState([]);
  const [userId, setUserId] = useState("");
  const [fileId, setFileId] = useState(0);
  const [filePathView, setFilePathView] = useState("");
  const [addFileInfo, setAddFileInfo] = useState();
  const [isHeadOffice, setIsHeadOffice] = useState(false);
  const [visibleDeleteFileConfirm, setVisibleDeleteFileConfirm] =
    useState(false);
  const [visibleDeleteFolderConfirm, setVisibleDeleteFolderConfirm] =
    useState(false);
  const [publishDateForm, setPublishDateForm] = useState(false);
  const [fileIdDelete, setFileIdDelete] = useState(false);
  const [newFolderClick, setNewFolderClick] = useState(false);
  const [form] = Form.useForm();

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [expandedKeysUserAccess, setExpandedKeysUserAccess] = useState([]);
  const [checkedKeysUserAccess, setCheckedKeysUserAccess] = useState([]);
  const [selectedKeysUserAccess, setSelectedKeysUserAccess] = useState([]);
  const [accessType, setAccessType] = useState("");
  // const [autoExpandParent, setAutoExpandParent] = useState < boolean > true;

  // const [userId, setUserId] = useState("");
  // const [fileId, setFileId] = useState(0);
  const [fileIdEdit, setFileIdEdit] = useState(0);
  const [treeDataUserAccess, setTreeDataUserAccess] = useState([]);
  const [formValueIsViewable, setFormValueIsViewable] = useState(false);
  const [formValueIsDownloadable, setFormValueIsDownloadable] = useState(false);
  const [formValueFileName, setFormValueFileName] = useState(false);
  const [formValueIsBaseFolder, setFormValueIsBaseFolder] = useState(false);

  useEffect(() => {
    // setFolderType(props.FolderType);
    //alert(props.FolderType);
    let FolderTypeParam = 0;
    if (props.FolderType === "academic") {
      FolderTypeParam = 1;
      setFolderType(1);
    } else if (props.FolderType === "admin") {
      FolderTypeParam = 2;
      setFolderType(2);
    }
    try {
      let UserId = "";
      if (props.UserId === null) {
        UserId = localStorage.getItem("UserInfoResourcesMaterial");
      } else {
        UserId = props.UserId;
        localStorage.setItem("UserInfoResourcesMaterial", UserId);
      }
      setUserId(UserId);
      LoadAllFolders(FolderTypeParam, UserId);
    } catch {}

    // form.setFieldsValue({
    //   PublishDate: moment().format(),
    // });
  }, []);

  const LoadAllFolders = (FolderTypeParam, UserId) => {
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/GetAllFolders?FolderType=" +
      FolderTypeParam +
      "&UserId=" +
      UserId;

    message.loading("Getting data from server. Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        setIsHeadOffice(resp.data.lstFolders[0].IsHeadOffice);
        var nested = getNestedChildren(resp.data.lstFolders, 0);
        // console.log(nested);
        // console.log(treeData1);
        let expandedKeyFolders = [];
        expandedKeyFolders.push(resp.data.lstFolders[0].Id.toString());
        debugger;
        setExpandedKeys(expandedKeyFolders);
        setTreeData(nested);
        message.destroy();
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const LoadAllFiles = (FolderId, IsShowDeleted) => {
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/GetAllFiles?FolderId=" +
      FolderId +
      "&UserId=" +
      userId;
    message.loading("Getting data from server. Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        let ViewFilesArray = [];
        for (let i = 0; i < resp.data.lstFiles.length; i++) {
          let obj = {
            Id: resp.data.lstFiles[i].Id,
            IsShow: false,
            // key: resp.data.lstFiles[i].Id, //Math.floor(Math.random() * 10000),
          };
          // if (obj.key === undefined) {
          //   alert("key is undefined");
          //   obj.key = Math.floor(Math.random() * 10000);
          // }
          ViewFilesArray.push(obj);
        }

        setFilesList(resp.data.lstFiles);
        setVisibleViewFile(ViewFilesArray);

        message.destroy();
        if (IsShowDeleted) {
          message.success("File Deleted Successfully!!!");
        }
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const onSelect = (selectedKeys, info) => {
    setSelectedNode(info.node.key);
    setSelectedNodeTitle(info.node.title);
    LoadAllFiles(info.node.key);
    // let expanededKeysAll = expandedKeys;
    // if (expanededKeysAll.indexOf(info.node.key) > -1) {
    //   let index = expanededKeysAll.indexOf(info.node.key);
    //   expanededKeysAll.splice(index, -1);
    // } else {
    //   expanededKeysAll.push(info.node.key);
    // }

    // setExpandedKeys(expanededKeysAll);

    // addDataToId(treeData, "ChildLeaf2", [
    //   { title: "ChildLeaf5", key: "ChildLeaf5" },
    // ]);
  };

  // const addDataToId = (arr, key, children) => {
  //   arr.forEach((i) => {
  //     if (i.key == key) {
  //       i.children = [...(i.children || []), ...children];
  //     } else {
  //       addDataToId(i.children || [], key, children);
  //     }
  //   });
  // };

  function getNestedChildren(arr, ParentId) {
    var out = [];
    for (var i in arr) {
      if (arr[i].ParentId == ParentId) {
        var children = getNestedChildren(arr, arr[i].Id);

        if (children.length) {
          arr[i].children = children;
        }

        let newArraryWithoutChildren = {
          title: arr[i].Name,
          key: arr[i].Id.toString(),
          icon: <FolderTwoTone size={48} />,
        };

        let newArraryWithChildren = {
          title: arr[i].Name,
          key: arr[i].Id.toString(),
          children: arr[i].children,
          icon: <FolderTwoTone size={48} />,
        };
        out.push(
          arr[i].children === undefined
            ? newArraryWithoutChildren
            : newArraryWithChildren
        );
      }
    }
    return out;
  }

  const AddFileClick = () => {
    debugger;
    if (!fileSource && fileIdEdit === 0) {
      message.warning("Please upload file");
    } else if (addFileInfo.ExpiryDate < addFileInfo.PublishDate) {
      message.warning(
        "Expiry Date must be greater than or equal to publish date"
      );
    } else {
      form
        .validateFields()
        .then((values) => {
          if (!fileSource) {
            message.loading("Data is uploading. Please Wait 😉", 100000);
            SaveFile("");
          } else {
            let formdata = new FormData();

            formdata.append("name", fileSource);
            formdata.append("test", true);
            let url =
              process.env.REACT_APP_APIURL + "ResourcesMaterialApi/SaveFile";
            message.loading("Data is uploading. Please Wait 😉", 100000);
            axios({
              url: url,
              method: "POST",
              data: formdata,
            })
              .then((res) => {
                SaveFile(res.data);
              })
              .catch((err) => {
                // message.destroy();
                alert(err);
                message.destroy();
              });
          }
        })
        .catch((errorInfo) => {});
    }
  };

  const SaveFile = (data) => {
    let obj = {
      BrId: 1,
      FileName: data.FileName != undefined ? data.FileName : "",
      FileType: data.FileExt != undefined ? data.FileExt : "",
      FilePath: data.FilePath != undefined ? data.FilePath : "",
      FolderId: parseInt(selectedNode),
      IsDownloadable: addFileInfo.IsDownloadable,
      IsViewable: addFileInfo.IsViewable,
      PublishDate: moment(addFileInfo.PublishDate).format("MM/DD/YYYY"),
      ExpiryDate: moment(addFileInfo.ExpiryDate).format("MM/DD/YYYY"),
      CreatedBy: 1,
      Id: fileIdEdit,
      IsBaseFolder: addFileInfo.IsBaseFolder,
      FolderType: folderType,
    };

    if (fileIdEdit != 0) {
      obj.IsDownloadable = formValueIsDownloadable;
      obj.IsViewable = formValueIsViewable;
    }
    let url =
      process.env.REACT_APP_APIURL_POST + "ResourcesMaterialApi/AddFile";
    axios({ method: "post", url: url, data: obj })
      .then((resp) => {
        message.destroy();
        let msg = resp.data.Message;
        if (msg === "ok") {
          message.success("File added Successfully!!!");
          setAddFileInfo({
            ...addFileInfo,
            PublishDate: moment(),
            BrId: 0,
            CreatedBy: 0,
            ExpiryDate: moment(),
            FileName: "",
            FilePath: "",
            FileType: "",
            FolderId: 0,
          });

          setVisibleAddFile(false);
          setFileIdEdit(0);
          LoadAllFiles(selectedNode);
        }
      })
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
    setVisibleAddFolder(false);
  };

  const FileUploadClick = (e) => {
    // message.loading("File Uploading is in progress. Please Wait 😉", 100000);
    let file = e.target.files[0];
    setFileSource(file);

    e.target.files = null;
  };

  const AddFolderClick = () => {
    let obj = {
      Name: folderName,
      ParentId: parseInt(selectedNode),
      CreatedBy: 1,
      FolderType: folderType,
      Id: newFolderClick ? 0 : selectedNode,
    };
    message.loading("Data is saving. Please Wait 😉", 100000);
    let url =
      process.env.REACT_APP_APIURL_POST + "ResourcesMaterialApi/AddFolder";
    axios({ method: "post", url: url, data: obj })
      .then((resp) => {
        let msg = resp.data.Message;
        if (msg === "ok") {
          message.destroy();
          message.success("Folder added succesfully");
          setFolderName("");
          setSelectedNodeTitle("");
          setSelectedNode("");
          LoadAllFolders(folderType, userId);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setVisibleAddFolder(false);
  };

  const NewFileClick = () => {
    if (selectedNode === "") {
      message.warning("Please select folder");
    } else {
      setFileIdEdit(0);
      setVisibleAddFile(true);

      form.setFieldsValue({
        PublishDate: moment(),
        ExpiryDate: moment(),
      });

      setAddFileInfo({
        ...addFileInfo,
        PublishDate: moment(),
        ExpiryDate: moment(),
        IsViewable: true,
        IsDownloadable: false,
        IsBaseFolder: false,
      });
      setFormValueIsDownloadable(false);
      setFormValueIsViewable(true);
      setFormValueIsBaseFolder(false);
    }
  };

  const NewFolderClick = () => {
    if (selectedNode === "") {
      message.warning("Please select folder");
    } else {
      setVisibleAddFolder(true);
      setNewFolderClick(true);
    }
  };
  const onExpand = (expandedKeys, { expanded: bool, node }) => {
    setExpandedKeys(expandedKeys);
  };

  const onExpandUserAccess = (
    expandedKeysUserAccess,
    { expanded: bool, node }
  ) => {
    setExpandedKeysUserAccess(expandedKeysUserAccess);
  };

  const onFinish = () => {};

  const onFinishFailed = () => {
    message.error("Submit failed!");
  };
  const onViewFile = (index, FileType) => {
    if (FileType != "jpg") {
      if (FileType === "rar" || FileType === "zip") {
        message.warning("This file is not viewable");
      } else {
        message.loading("File is in progress. Please Wait 😉", 100000);

        let newArray = visibleViewFile;
        newArray[index].IsShow = true;
        setVisibleViewFile([...visibleViewFile, newArray[index]]);
        setTimeout(() => {
          message.destroy();
        }, 5000);
      }
    }
    // setFilePathView("http://localhost/ApiSMS" + FilePath);
    //setVisibleViewFile(true);
  };

  const onViewFileClose = (index) => {
    let newArray = visibleViewFile;
    newArray[index].IsShow = false;
    setVisibleViewFile([...visibleViewFile, newArray[index]]);
    // alert("Close");
    try {
      var video = document.getElementById("videoContainer" + index);
      video.pause();
    } catch {}
    // setFilePathView("http://localhost/ApiSMS" + FilePath);
    //setVisibleViewFile(true);
  };

  const RefreshIframe = (index) => {
    message.loading("File is in progress. Please Wait 😉", 100000);
    let newArray = visibleViewFile;
    //newArray[index].key = Math.floor(Math.random() * 10000);
    setVisibleViewFile([...visibleViewFile, newArray[index]]);
    setTimeout(() => {
      message.destroy();
    }, 5000);
  };

  const AddUsersAccess = (FileId) => {
    setFileId(FileId);
    console.log(fileId);
    setAddUserAccess(true);
    LoadAllUserAccess(FileId, "File");
    // childRef.current.getAlert("Parent");
    // alert("Hi");
  };

  const LoadAllUserAccess = (FileId, AccessType) => {
    //AccessType will be either File or Folder
    setAccessType(AccessType);
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/GetUsersForAccess?FileId=" +
      FileId +
      "&UserId=" +
      userId +
      "&AccessType=" +
      AccessType;
    message.loading("Getting data from server. Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        var nested = getNestedChildrenUserAccess(resp.data.lstUsers, 0);
        let lstUserId = resp.data.lstUsers
          .filter(function (item) {
            return item.IsSelected === true;
          })
          .map((a) => a.key);
        setCheckedKeysUserAccess(lstUserId);
        setExpandedKeysUserAccess(["T1"]);
        // console.log(nested);
        // console.log(treeData1);
        setTreeDataUserAccess(nested);
        message.destroy();
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  function getNestedChildrenUserAccess(arr, ParentId) {
    var out = [];
    for (var i in arr) {
      if (arr[i].ParentId == ParentId) {
        var children = getNestedChildrenUserAccess(arr, arr[i].key);

        if (children.length) {
          arr[i].children = children;
        }

        let newArraryWithoutChildren = {
          title: arr[i].title,
          key: arr[i].key,
        };

        let newArraryWithChildren = {
          title: arr[i].title,
          key: arr[i].key,
          children: arr[i].children,
        };
        out.push(
          arr[i].children === undefined
            ? newArraryWithoutChildren
            : newArraryWithChildren
        );
      }
    }
    return out;
  }

  // const onExpand = (expandedKeysValue) => {
  //   console.log("onExpand", expandedKeysValue);
  //   // if not set autoExpandParent to false, if children expanded, parent can not collapse.
  //   // or, you can remove all expanded children keys.
  //   setExpandedKeys(expandedKeysValue);
  //   setAutoExpandParent(false);
  // };

  const onCheckUserAccess = (checkedKeysValue) => {
    console.log("onCheck", checkedKeysValue);
    setCheckedKeysUserAccess(checkedKeysValue);
  };

  // const onSelect = (selectedKeysValue, info) => {
  //   console.log("onSelect", info);
  //   setSelectedKeys(selectedKeysValue);
  // };

  const onUpdateUserAccess = () => {
    let FileIdOrFolderId = accessType === "File" ? fileId : selectedNode;

    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/SaveUsersForAccess?FileId=" +
      FileIdOrFolderId +
      "&Users=" +
      checkedKeysUserAccess +
      "&AccessType=" +
      accessType;
    message.loading("Data is updating. Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        message.success("User Access successfully updated");
        setAddUserAccess(false);
        message.destroy();
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const EditFile = (FileId) => {
    setFileIdEdit(FileId);
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/GetFileInfo?FileId=" +
      FileId;
    message.loading("Getting data from server. Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        setVisibleAddFile(true);
        let FileInfo = resp.data.FileInfo;

        form.setFieldsValue({
          PublishDate: moment(FileInfo.PublishDate),
          ExpiryDate: moment(FileInfo.ExpiryDate),
          IsViewable: FileInfo.IsViewable,
          IsDownloadable: FileInfo.IsDownloadable,
        });

        setAddFileInfo({
          ...addFileInfo,
          PublishDate: moment(FileInfo.PublishDate),
          ExpiryDate: moment(FileInfo.ExpiryDate),
        });
        setFormValueIsDownloadable(FileInfo.IsDownloadable);
        setFormValueIsViewable(FileInfo.IsViewable);
        setFormValueIsBaseFolder(false);
        setFormValueFileName(FileInfo.FileName);

        message.destroy();
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const handleMenuClick = (e, Id) => {
    console.log(e + "Id: " + Id);
    if (e.key === "1") {
      AddUsersAccess(Id);
    } else if (e.key === "2") {
      EditFile(Id);
    } else if (e.key === "3") {
      setVisibleDeleteFileConfirm(true);
      setFileIdDelete(Id);
    }
  };

  const handleOkDeleteFileConfirm = () => {
    setVisibleDeleteFileConfirm(false);
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/DeleteFile?FileId=" +
      fileIdDelete;

    message.loading("Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        message.destroy();
        if (resp.data.Message === "ok") {
          LoadAllFiles(selectedNode, true);
        } else {
          message.error(resp.data.Message);
        }
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const EditFolder = () => {
    if (selectedNode === "") {
      message.warning("Please select folder");
    } else {
      setFolderName(selectedNodeTitle);
      setVisibleAddFolder(true);
      setNewFolderClick(false);
    }
  };

  const DeleteFolder = () => {
    if (selectedNode === "") {
      message.warning("Please select folder");
    } else {
      setVisibleDeleteFolderConfirm(true);
    }
  };

  const handleOkDeleteFolderConfirm = () => {
    let url =
      process.env.REACT_APP_APIURL +
      "ResourcesMaterialApi/DeleteFolder?Id=" +
      selectedNode +
      "&UserId=" +
      userId;
    message.loading("Please Wait 😉", 100000);
    axios
      .get(url)
      .then((resp) => {
        message.destroy();
        if (resp.data.Message === "ok") {
          setVisibleDeleteFolderConfirm(false);
          LoadAllFolders(folderType, userId);
        } else if (resp.data.Message === "Files") {
          message.error(
            "This folder contains files, please delete all files of this folders first"
          );
        } else if (resp.data.Message === "Folders") {
          message.error(
            "This folder contains folders, please delete all folders of this folders first"
          );
        } else {
          message.error(resp.data.Message);
        }
      }) //Then Call back respAppVersion
      .catch((error) => {
        console.log(error);
        message.destroy();
      });
  };

  const GetIcon = (FileType) => {
    if (FileType === "rar" || FileType === "zip") {
      return <FileZipTwoTone style={{ fontSize: 36, display: "flow-root" }} />;
    } else if (FileType === "doc" || FileType === "docx") {
      return <FileWordTwoTone style={{ fontSize: 36, display: "flow-root" }} />;
    } else if (FileType === "xls" || FileType === "xlsx") {
      return (
        <FileExcelTwoTone style={{ fontSize: 36, display: "flow-root" }} />
      );
    } else if (FileType === "pdf") {
      return <FilePdfTwoTone style={{ fontSize: 36, display: "flow-root" }} />;
    } else if (FileType === "mp4") {
      return (
        <VideoCameraTwoTone style={{ fontSize: 36, display: "flow-root" }} />
      );
    } else {
      return <FileTwoTone style={{ fontSize: 36, display: "flow-root" }} />;
    }
  };

  function disabledDate(current) {
    // console.log(addFileInfo.PublishDate);
    // console.log(moment().endOf("day"));
    // // Can not select days before today and today
    // let aa = addFileInfo.PublishDate < moment().endOf("day");
    // console.log("current:" + aa);
    // console.log("currentDate:" + current);
    // console.log("EndOfDay:" + moment().endOf("day"));
    // let currentDiff = current < moment().endOf("day");
    // console.log("currentDiff:" + currentDiff);

    let isShowDate = false;

    if (addFileInfo.PublishDate != undefined) {
      if (current < addFileInfo.PublishDate) {
        isShowDate = true;
      }
      return isShowDate;
    }

    return isShowDate;
  }

  const AssignFolder = () => {
    // setSelectedNode
    setAddUserAccess(true);
    LoadAllUserAccess(selectedNode, "Folder");
  };

  return (
    <>
      <div className="page-content">
        <h4>Resources</h4>
        {/* <DocViewer documents={docs} /> */}
        {/* <FileViewer fileType={type} filePath={file} /> */}
        <div className="row">
          <div className="col-md-4">
            {isHeadOffice && (
              <>
                <Button onClick={NewFolderClick}> Create Folder</Button>
                <span style={{ paddingRight: 40 }}></span>
                <Tooltip title="Edit Folder">
                  <EditTwoTone onClick={EditFolder} />
                </Tooltip>
                <span style={{ paddingRight: 40 }}></span>
                <Tooltip title="Delete Folder">
                  <Popconfirm
                    title="Are you sure "
                    visible={visibleDeleteFolderConfirm}
                    onConfirm={handleOkDeleteFolderConfirm}
                    onCancel={() => setVisibleDeleteFolderConfirm(false)}
                  >
                    <DeleteTwoTone onClick={DeleteFolder} />
                  </Popconfirm>
                </Tooltip>
                <span style={{ paddingRight: 40 }}></span>
                <Tooltip title="Assign Folder to Users">
                  <UsergroupAddOutlined onClick={AssignFolder} />
                </Tooltip>
              </>
            )}

            <div
              style={{
                display: "block",
                paddingtop: 15,
              }}
              className="ResouceMaterialTree"
            >
              <Modal
                title="User Access Setting"
                centered
                visible={addUserAccess}
                onOk={onUpdateUserAccess}
                onCancel={() => setAddUserAccess(false)}
                width="50%"
                height={400}
              >
                <div>
                  {/* <UserAccessTree FileId={fileId} UserId={userId} /> */}
                  <Tree
                    checkable
                    onExpand={onExpandUserAccess}
                    expandedKeys={expandedKeysUserAccess}
                    // autoExpandParent={autoExpandParent}
                    onCheck={onCheckUserAccess}
                    checkedKeys={checkedKeysUserAccess}
                    // defaultExpandedKeys={["T1"]}
                    // onSelect={onSelect}
                    // selectedKeys={selectedKeys}
                    treeData={treeDataUserAccess}
                  />
                </div>
              </Modal>
              <Modal
                title="Upload Content"
                centered
                visible={visibleAddFile}
                onOk={() => AddFileClick()}
                onCancel={() => setVisibleAddFile(false)}
                width="60%"
              >
                <Form
                  form={form}
                  layout="vertical"
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <div className="container-fluid p-4 m-1 workingArea">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <Form.Item
                          name="PublishDate"
                          label="Publish Date"
                          rules={[{ type: "date", required: true }]}
                        >
                          <DatePicker
                            //defaultValue={moment().format("DD/MM/YYYY")}
                            // initialValue={publishDateForm}
                            format="DD/MM/YYYY"
                            onChange={(e) =>
                              setAddFileInfo({ ...addFileInfo, PublishDate: e })
                            }
                            className="allTextInput w-100"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <Form.Item
                          name="ExpiryDate"
                          label="Expiry Date"
                          rules={[{ type: "date", required: true }]}
                        >
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(e) =>
                              setAddFileInfo({ ...addFileInfo, ExpiryDate: e })
                            }
                            disabledDate={disabledDate}
                            className="allTextInput w-100"
                          />
                        </Form.Item>
                      </div>

                      <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item name="IsViewable" label="Is Viewable">
                          <div style={{ width: 50 }}>
                            <Switch
                              checked={formValueIsViewable}
                              onChange={(e) => {
                                setAddFileInfo({
                                  ...addFileInfo,
                                  IsViewable: e,
                                });
                                setFormValueIsViewable(e);
                              }}
                              className="allTextInput w-100"
                            />
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-6 col-1">
                        <Form.Item
                          name="IsDownloadable"
                          label="Is Downloadable"
                        >
                          <div style={{ width: 40 }}>
                            <Switch
                              checked={formValueIsDownloadable}
                              onChange={(e) => {
                                setAddFileInfo({
                                  ...addFileInfo,
                                  IsDownloadable: e,
                                });
                                setFormValueIsDownloadable(e);
                              }}
                              className="allTextInput w-100"
                            />
                          </div>
                        </Form.Item>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 ">
                        <Form.Item name="FileName" label="File">
                          {/* <div style={{ display: "inline" }}> */}
                          {/* <Upload onChange={FileUploadClick}>
                            <Button>Select file</Button>
                          </Upload> */}
                          <input
                            type="file"
                            // value=""
                            onChange={FileUploadClick}
                            // accept="image/gif, image/jpeg"
                          />
                          <span>{formValueFileName}</span>
                          {/* </div>
                          <div style={{ display: "inline" }}>
                            <Input
                              className="allTextInput"
                              placeholder=""
                              disabled
                            />
                          </div> */}
                        </Form.Item>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item name="IsBaseFolder" label="Is Base Folder">
                          <div style={{ width: 50 }}>
                            <Switch
                              onChange={(e) => {
                                setAddFileInfo({
                                  ...addFileInfo,
                                  IsBaseFolder: e,
                                });
                              }}
                              className="allTextInput w-100"
                            />
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal>

              <Modal
                title="New Directory"
                centered
                visible={visibleAddFolder}
                onOk={() => AddFolderClick()}
                onCancel={() => setVisibleAddFolder(false)}
                width="30%"
              >
                <input
                  name="FolderName"
                  type="text"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                />
              </Modal>

              {/* <Modal
                title="View File"
                centered
               
                width="60%"
              >
                
                <div className="col-md-12">
                  <div
                    style={{
                      position: "absolute",
                      top: 8,
                      left: 800,
                      height: 34,
                      right: 66,
                    }}
                  >
                    <button
                      style={{
                        height: 46,
                        backgroundColor: "white",
                        width: 42,
                        borderWidth: 0,
                      }}
                    ></button>
                  </div>
                  <iframe
                    src={
                      "https://docs.google.com/viewer?url=" +
                      filePathView +
                      "&embedded=true"
                    }
                    style={{ width: 800, height: 550, border: "none" }}
                  ></iframe>
                </div>
              </Modal> */}
              <Tree
                treeData={treeData}
                showIcon
                // autoExpandParent={true}
                onSelect={onSelect}
                showLeafIcon={false}
                // parent
                expandedKeys={expandedKeys}
                onExpand={onExpand}
                //defaultExpandedKeys={["2", "5"]}
                // defaultSelectedKeys={[defaultSelected]}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div>
              <Popconfirm
                title="Are you sure "
                visible={visibleDeleteFileConfirm}
                onConfirm={handleOkDeleteFileConfirm}
                onCancel={() => setVisibleDeleteFileConfirm(false)}
              ></Popconfirm>
              {isHeadOffice && (
                <Button onClick={NewFileClick}> Upload File</Button>
              )}
            </div>
            <div className="file-box-content">
              {filesList.map((value, index) => {
                return (
                  <>
                    <Modal
                      title={"View " + value.FileName}
                      centered
                      visible={
                        visibleViewFile.length > 0
                          ? visibleViewFile[index] != undefined
                            ? visibleViewFile[index].IsShow
                            : false
                          : false
                      }
                      onOk={() => onViewFileClose(index)}
                      onCancel={() => onViewFileClose(index)}
                      width="80%"
                    >
                      {(value.FileType === "pdf" ||
                        value.FileType === "docx" ||
                        value.FileType === "doc" ||
                        value.FileType === "xls" ||
                        value.FileType === "xlsx" ||
                        value.FileType === "ppt" ||
                        value.FileType === "pptx" ||
                        value.FileType === "csv" ||
                        value.FileType === "txt") && (
                        <div
                          className="col-md-12"
                          style={{ textAlign: "center" }}
                        >
                          <div>
                            <button
                              onClick={() => RefreshIframe(index)}
                              className="btn btn-info"
                            >
                              Refresh
                            </button>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 50,
                              left: 800,
                              height: 34,
                              right: 66,
                            }}
                          >
                            <button
                              style={{
                                height: 46,
                                backgroundColor: "white",
                                width: 42,
                                borderWidth: 0,
                              }}
                            ></button>
                          </div>
                          <iframe
                            key={
                              visibleViewFile.length > 0
                                ? Math.floor(Math.random() * 100000)
                                : 0
                            }
                            id={"iframe" + index}
                            src={
                              "https://docs.google.com/viewer?url=" +
                              process.env.REACT_APP_API_Files +
                              value.FilePath +
                              "?ignore=" +
                              Math.floor(Math.random() * 1000) +
                              "&embedded=true&ignore=" +
                              Math.floor(Math.random() * 1000)
                            }
                            style={{ width: 800, height: 550, border: "none" }}
                          ></iframe>
                        </div>
                      )}

                      {(value.FileType === "mp4" ||
                        value.FileType === "mkv" ||
                        value.FileType === "avi" ||
                        value.FileType === "mov" ||
                        value.FileType === "wmv") && (
                        <div className="col-md-12">
                          <video
                            id={"videoContainer" + index}
                            controls
                            autoPlay
                            controlsList="nodownload"
                            onContextMenu={(e) => {
                              e.preventDefault();
                            }}
                            style={{ width: "100%", height: "400px" }}
                            src={
                              process.env.REACT_APP_API_Files + value.FilePath
                            }
                            // src="http://api.ngs.edu.pk/Content/Files/eLearning/Assignments/VID-20200902-WA0002_20200902090532791.mp4"
                          />
                        </div>
                      )}
                    </Modal>
                    <div className="file-box">
                      <div>
                        {isHeadOffice && (
                          <div
                            style={{
                              textAlign: "left",
                              display: "inline",
                            }}
                            //onClick={() => AddUsersAccess(value.Id)}
                          >
                            {/* <a href="#">
                              <UsergroupAddOutlined style={{ fontSize: 18 }} />
                            </a> */}
                            <Dropdown.Button
                              overlay={
                                <Menu
                                  onClick={(e) => handleMenuClick(e, value.Id)}
                                >
                                  <Menu.Item key="1">
                                    Assign User Access
                                  </Menu.Item>
                                  <Menu.Item key="2">Edit</Menu.Item>
                                  <Menu.Item key="3">Delete</Menu.Item>
                                </Menu>
                              }
                            ></Dropdown.Button>
                          </div>
                        )}
                        <div
                          style={{
                            float: "right",
                            display: "inline",
                            paddingRight: 5,
                          }}
                        >
                          {value.IsDownloadable && (
                            <Tooltip placement="right" title="Download File">
                              <a
                                href={
                                  process.env.REACT_APP_API_Files +
                                  value.FilePath
                                }
                                target="_blank"
                              >
                                <DownloadOutlined style={{ fontSize: 18 }} />
                              </a>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div onClick={() => onViewFile(index, value.FileType)}>
                        <a className="">
                          {/* <FileTwoTone
                            style={{ fontSize: 36, display: "flow-root" }}
                          /> */}
                          {value.FileType != "jpg" && GetIcon(value.FileType)}
                          {value.FileType === "jpg" && (
                            <div style={{ display: "table", margin: "auto" }}>
                              <Image
                                width={36}
                                height={36}
                                style={{ display: "flow-root" }}
                                src={
                                  process.env.REACT_APP_API_Files +
                                  value.FilePath
                                }
                              />
                            </div>
                            // <Image
                            //   width={36}
                            //   height={36}
                            //   style={{ display: "flow-root" }}
                            //   src={
                            //     process.env.REACT_APP_API_Files + value.FilePath
                            //   }
                            ///>
                            // <ImageViewer>
                            //   <img
                            //     src={
                            //       process.env.REACT_APP_API_Files +
                            //       value.FilePath
                            //     }
                            //     width={30}
                            //     height={30}
                            //   />
                            // </ImageViewer>
                          )}
                        </a>

                        <div className="text-center">
                          <i className="FileTwoTone  text-primary"></i>
                          <Tooltip
                            placement="right"
                            title={
                              "View " + value.FileName + "." + value.FileType
                            }
                          >
                            <h6 className="text-truncate mt-2">
                              {value.FileName + "." + value.FileType}
                            </h6>
                          </Tooltip>
                          <small className="text-muted">{value.DateTime}</small>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {/* <div className="file-box">
                <a href="#" className="download-icon-link">
                  <FileTwoTone style={{ fontSize: 24 }} />
                </a>
                <div className="text-center">
                  <i className="FileTwoTone  text-primary"></i>
                  <h6 className="text-truncate">Admin_Panel</h6>
                  <small className="text-muted">06 March 2019 / 5MB</small>
                </div>
              </div> */}
              {/* <div className="file-box">
                <a href="#" className="download-icon-link">
                  <i className="dripicons-download file-download-icon"></i>
                </a>
                <div className="text-center">
                  <i className="far fa-file-alt text-primary"></i>
                  <h6 className="text-truncate">Admin_Panel</h6>
                  <small className="text-muted">06 March 2019 / 5MB</small>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-md-12">
            <div
              style={{ position: "absolute", top: 8, left: 762, height: 34 }}
            >
              <button
                style={{
                  height: 46,
                  backgroundColor: "white",
                  width: 42,
                  borderWidth: 0,
                }}
              ></button>
            </div>
            <iframe
              src={
                "https://docs.google.com/viewer?url=http://api2021.ngs.edu.pk/content/files/test/sample-pdf-download-10-mb.pdf&embedded=true"
              }
              style={{ width: 800, height: 800, border: "none" }}
            ></iframe>

           
          </div> */}
        </div>
      </div>
    </>
  );
};
export default FolderSide;
