
import { Drawer } from 'antd';
import CategoryNotification from '../CategoryNotifications/CategoryNotification';


function NotificationDrawer(props: any): any {
  
  return (
    <>
      <Drawer
        style={{zIndex: '10000'}}
        title="Notifications"
        placement='right'
        width={400}
        onClose={props.handleVisibility}
        visible={props.visibility}>
          <CategoryNotification />
      </Drawer>
    </>
  );
};

export default NotificationDrawer;