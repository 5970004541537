import { Row, Select, Button, Tooltip, Upload, message } from 'antd';
import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import {  useState } from 'react';
import FileUpload from '../../../../Components/MainComponents/GlobalCustomUI/Upload/FileUpload'
import { AnyMxRecord } from 'dns';


const { Option } = Select;
const asprops = {
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text',
    },
    
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };


export default function Attachments(props: any) {
    var [atts,addAtts]=useState<any>([]);
    var [selectedValue, setSelectedValue] = useState<string>();
    var [selectionArr, setSelectionArr] = useState<Array<number>>([]);
    var [AddBtnstate, setAddBtnState]  = useState(false);
    var [optKey, setOptKey] = useState<number>(0);

    var updateSelection = (value:any, prop: any)=>{
        var addAble =true;
        selectionArr.forEach(element => {
            if(element === prop.key)
                {
                    setAddBtnState(false)
                    addAble = false;
                }
        });
        if(addAble)
        {
            setAddBtnState(true);
            setSelectedValue(value);
            setOptKey(prop.key)
        }
    }

    const addFile = ()=>{
        setAddBtnState(false);
        setSelectionArr([...selectionArr, optKey])
        addAtts([...atts, <FileUpload currentUploadName={selectedValue}  /> ])
    }

    
    return (
        <div className="jumbotron" style={{backgroundColor: 'white'}}>
            <div className='row'>
                <div className='col-lg-6'>
                    <h5>Important Docs Upload</h5>
                    <Row>
                        <Select className='allTextInput' size='small' onChange={updateSelection} 
                            style={{ width: 200 }}
                            placeholder="Select Attachment">
                            <Option key={1} value="Picture" >Picture</Option>
                            <Option key={2} value="Parent CNIC Front" >Parent CNIC Front</Option>
                            <Option key={3} value="Parent CNIC Back">Parent CNIC Back</Option>
                            <Option key={4} value="B-Form">B-Form</Option>
                            <Option key={5} value="Pre-Docs">Pre. School-Docs</Option>
                            <Option key={6} value="Leaving certificate">Leaving certificate</Option>
                            <Option key={7} value="Birth Certificate">Birth Certificate</Option>
                        </Select>
                        <Tooltip title="Add">
                            {AddBtnstate && <Button type="primary" disabled={!AddBtnstate}  onClick={addFile} icon={<PlusCircleOutlined />} className='mt-1 ml-1 allTextInput' />}
                            {!AddBtnstate &&<Button type="primary" disabled={!AddBtnstate}  onClick={addFile} icon={<PlusCircleOutlined />} className='mt-1 ml-1 allTextInput' />}
                        </Tooltip>
                    </Row>
                </div>
                <div className='col-lg-6'>
                    <h5>Bulk Upload (Maximum 5 Files)</h5>
                    <Upload {...asprops}>
                        <Button style={{borderRadius: '10px'}} icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-lg-6'>
                    {
                        atts && atts.map((item :AnyMxRecord, index: number)=>
                        <div className='row'>
                            <div className='col-1 d-flex align-items-sm-center'>
                                <span className='badge badge-dark m-4'>{index + 1}</span>
                            </div>
                            <div className='col-11'>
                                {item}
                            </div>
                        </div>)
                    }
                </div>
            </div>

        </div>
    )

}