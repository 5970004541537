import { Typography, Select, Form, message, Checkbox, DatePicker, Input, Button } from "antd";
import { useCallback, useState } from "react";




const { Text, Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

export default function LeavingForm() {
    const [form] = Form.useForm();
    const [leavingCtrlVisible, setLeavingCtrlVisible] = useState(false);
    const [rejoiningRemarksVisible, setRejoiningRemarksVisible] = useState(false);
    const leavingCtrlVisibilityHandler = useCallback((e: any) => {
        e !== 1 && setLeavingCtrlVisible(true)
        e === 1 && setLeavingCtrlVisible(false)
    }, [])
    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };


    // const onFill = () => {
    //     form.setFieldsValue({
    //         url: 'https://taobao.com/',
    //     });
    // };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
                <h3 style={{textAlign: 'center'}}>Leaving Form</h3>
            <div className="container-fluid p-4" style={{ backgroundColor: 'white' }}>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Student Id"
                            label="Student ID"
                            rules={[{ required: true }, { type: 'string', min: 6, warningOnly: true }]}>
                            <Title level={4}>
                                <Text code>Fall-2022-001</Text>
                            </Title>
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Student Name"
                            label="Student Name"
                            rules={[{ required: true }, { type: 'string', min: 6, warningOnly: true }]}>
                            <Title level={4}>
                                <Text code>Umer Ibrahim</Text>
                            </Title>
                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 2
                }
                <div className="row" >
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Conduct"
                            label="Conduct"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select optionFilterProp="children" showSearch placeholder="Conduct" className="w-100 allTextInput"  >
                                <Option value={1}>Good</Option>
                                <Option value={2}>Better </Option>
                                <Option value={3}>Excellent </Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Cetagory"
                            label="Cetagory"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select optionFilterProp="children" showSearch placeholder="Cetagory" className="w-100 allTextInput" onChange={leavingCtrlVisibilityHandler}  >
                                <Option value={1}>On Roll</Option>
                                <Option value={2}>Left</Option>
                                <Option value={3}>Passed Out</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-5  ">
                        <Checkbox >
                            Mobile App Access
                        </Checkbox>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-5  ">
                        <Checkbox >
                            Status
                        </Checkbox>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-5  ">
                        <Checkbox >
                            Login Status
                        </Checkbox>
                    </div>
                    {leavingCtrlVisible &&
                        <>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12 m-2 ">
                                <Form.Item
                                    name="Withdrawal Number"
                                    label="Withdrawal Number"
                                    rules={[{ type: 'string', warningOnly: true }]}>
                                        <Input type={'text'} name="Withdrawal Number" placeholder="Withdrawal Number" />
                                </Form.Item>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                                <Form.Item
                                    name="Leaving Date"
                                    label="Leaving Date"
                                    rules={[{ type: 'date', warningOnly: true }]}>
                                        <DatePicker placeholder='Leaving Date' />
                                </Form.Item>
                            </div>
                        </>}
                </div>
                {
                    //Row 3
                }
                <div className="row">
                    <div className="col-lg-11 col-md-11 col-sm-11 col-11 m-2 ">
                        <Form.Item
                            name="Leaving Date"
                            label="Leaving Date"
                            rules={[{ type: 'date', warningOnly: true }]}>
                                <TextArea placeholder="Letter Description" className="allTextInput" rows={8} />
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 4
                }
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Button >Get Leaving Certificate</Button>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Checkbox >
                            Print Letter Head
                        </Checkbox>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Button >Get Leaving Certificate</Button>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Button >Save</Button>
                        <Button >Print Fee Challan</Button>
                    </div>
                </div>
            </div>
        </Form>
    )

}