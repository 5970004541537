import React from 'react';
import { Table } from 'antd';
import { Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words'; /// this library just available for JSX not in TS and this a good feature in Ant Search by deafault
import { SearchOutlined } from '@ant-design/icons'; 

const data = [
  {
    key: '1',
    id: 528885,
    name: 'umer',
    relation: 'Father',
    cnic: '366032569774',
    cellNumber: '0325696902',
  },
  {
    key: '2',
    id: 598885,
    name: 'Irfan',
    relation: 'GrandFather',
    cnic: '366025588695',
    cellNumber: '030325697897',
  },
  {
    key: '3',
    id: 528985,
    name: 'Ali',
    relation: 'Father',
    cnic: '366035252841',
    cellNumber: '03156960532',
  },

];

class ParentList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  render() {
    const columns = [
      {
        title: 'P.ID',
        dataIndex: 'id',
        key: 'id',
        width: '30%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '20%',
        ...this.getColumnSearchProps('name'),
      },
      {
        title: 'P. NIC',
        dataIndex: 'cnic',
        key: 'cnic',
        ...this.getColumnSearchProps('cnic'),
        sorter: (a, b) => a.cnic.length - b.cnic.length,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'P. Number',
        dataIndex: 'cellNumber',
        key: 'cellNumber',
        ...this.getColumnSearchProps('cellNumber'),
        sorter: (a, b) => a.cellNumber.length - b.cellNumber.length,
        sortDirections: ['descend', 'ascend'],
      }
    ];
    return <Table columns={columns} dataSource={data} rowSelection={{
      type: 'radio',
      ...this.rowSelection,
    }}/>;
  }
}


export default ParentList ;