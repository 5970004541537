import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FeePlanData } from "../../../../StaticDataSource/StudentModule/DataRegistrationProcess";
import './table.css'

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  serial: string;
  id: string;
  feename: string;
  feetype: string;
  feeamount: string;
  feeoperation: string;
}

interface EditableTableState {
  dataSource: DataType[];
  count: number;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

class YearlyFeePlan extends React.Component<EditableTableProps, EditableTableState> {
  columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];

  constructor(props: EditableTableProps) {
    super(props);

    this.columns = [
      {
        title: 'Serial',
        dataIndex: 'serial',
        width: '10%',
        editable: false,
      },
      {
        title: 'ID',
        dataIndex: 'id',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => a.id - b.id,
        width: '10%',
        editable: false,

      },
      {
        title: 'Fee Name',
        dataIndex: 'feename',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => a.feename.length - b.feename.length,
        width: '25%',
        editable: false,
      },
      {
        title: 'Fee Type',
        dataIndex: 'feetype',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => a.feetype.length - b.feetype.lenght,
        width: '25%',
        editable: false,
        filters: [
          {
            text: 'Monthly',
            value: 'monthly',
          },
          {
            text: 'Stationary',
            value: 'stationary',
          },
          {
            text: 'Samester',
            value: 'Semester',
          },
          {
            text: 'Yearly',
            value: 'Yearly',
          },
        ],
        onFilter: (value, record: any) => record.feetype.indexOf(value) === 0,
      },
      {
        title: 'Fee Amount',
        dataIndex: 'feeamount',
        defaultSortOrder: 'descend',
        sorter: (a: any, b: any) => a.feeamount - b.feeamount,
        width: '10%',
        editable: true,
      },
      {
        title: 'Fee Operation',
        dataIndex: 'feeoperation',
        defaultSortOrder: 'descend',
        width: '5%',
        editable: false,
      },
    ];

    this.state = {
      dataSource: FeePlanData ,
      count: 2,
    };
  }

  handleDelete = (key: React.Key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
  };

  handleSave = (row: DataType) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  render() {
    const { dataSource } = this.state;
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: DataType) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns as ColumnTypes}
          pagination = {{pageSize: 5}}
          scroll={{x: true}}
        />
      </div>
    );
  }
}


export default YearlyFeePlan;