import { useContext, useState, useEffect, useRef } from 'react';
import { Table, Form, Popconfirm } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import 'antd/dist/antd.css';
import { Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, RetweetOutlined } from '@ant-design/icons';


const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
    key: string;
    id: number;
    TempName: string;
    TempStatus: string;
}

interface EditableRowProps {
    index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<Input>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface ColumnsType {
    key: React.Key;
    id: number;
    tempName: string;
    tempStatus: string;
}

interface EditableTableState {
    dataSource: ColumnsType[];
    count: number;
    searchText: '',
    searchedColumn: ''
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

class HeadList extends React.Component<EditableTableProps, EditableTableState> {
    columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[];

    constructor(props: EditableTableProps) {
        super(props);

        this.columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '30%',
                sorter: (a: any, b: any) => a.id - b.id,
            },
            {
                title: 'Template Name',
                dataIndex: 'tempName',
                width: '30%',
                ...this.getColumnSearchProps('tempName')
            },
            {
                title: 'Template Status',
                dataIndex: 'tempStatus',
                sorter: (a: any, b: any) => a.tempStatus.length - b.tempStatus.length,
            },
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (_: any, record: any): any => this.state.dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to reverse the current State of the given tamplate Row?" onConfirm={() => this.handleReverse(record.key)}>
                        <span style={{ cursor: 'pointer' }}><RetweetOutlined style={{fontSize: 24}} /></span>
                    </Popconfirm>
                ) : null
            }
        ];

        this.state = {
            dataSource: [
                {
                    key: '1',
                    id: 2502,
                    tempName: 'Tuition Fee',
                    tempStatus: 'Active',
                },
                {
                    key: '2',
                    id: 2503,
                    tempName: 'Monthly Fee',
                    tempStatus: 'Active',
                },
            ],
            count: 2,
            searchText: '',
            searchedColumn: ''
        };
    }

    getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {

                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space direction="vertical">
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                    <Space>
                        <Button size="small"
                            onClick={() => alert('Go back to default Record where the start')}>
                            Default Back
                        </Button>
                        <Button type="link" size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                this.setState({
                                    searchText: selectedKeys[0],
                                    searchedColumn: dataIndex,
                                });
                            }}>
                            Filter
                        </Button>
                    </Space>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => '', 100);
            }
        },
        render: (text: any) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });

    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    handleReverse = (key: React.Key) => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.key !== key) });
    };

    handleSave = (row: ColumnsType) => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: ColumnsType) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <Table
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns as ColumnTypes} />
            </div>
        );
    }
}


export default HeadList;