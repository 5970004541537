import { Link } from "react-router-dom";
import { Table, Tag } from 'antd';
import { StudentsListData } from '../../../../StaticDataSource/StudentModule/DataStudentList';
import React from 'react';
import 'antd/dist/antd.css';
import { Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
  EyeOutlined,
  MinusCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';

const rowSelection = {
  
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },

  getCheckboxProps: (record) => ({
    disabled: record.isDisabled === true, 
    name: record.studentName,
  }),
};
class StudentsTable extends React.Component{
  state = {
    searchText: '',
    searchedColumn: '',
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space direction="vertical">
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}>
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
          <Space>
            <Button type="link" size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });}}>
                Filter
              </Button>
            <Button size="small"
              onClick={() =>alert('Go back to default Record where the start') }>
              Default Back
            </Button>
          </Space>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });

  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
   


  Columns = [
    {
      title: 'S. ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      ...this.getColumnSearchProps('id'),
      width: 100,
      fixed: 'left'
    },
    {
      title: 'S. Name',
      dataIndex: 'studentName',
      width: 160,
      sorter: (a, b) => a.studentName.length - b.studentName.length,
      ...this.getColumnSearchProps('studentName'),
      fixed: 'left'
    },
    {
      title: 'Class Name',
      dataIndex: 'cName',
      width: 140,
      fixed: 'left',
      filters: [
        {
          text: 'Play Group (Red)',
          value: 'Play Group (Red)',
        },
        {
          text: 'Play Group (Blue)',
          value: 'Play Group (Blue)',
        }
      ],
      sorter: (a, b) => a.cName.length - b.cName.length,
      onFilter: (value, record) => record.cName.indexOf(value) === 0
    },
    {
      title: 'S. DOB',
      dataIndex: 'studentDOB',
      width: 110,
      sorter: (a, b) => a.studentDOB.length - b.studentDOB.length,
      ...this.getColumnSearchProps('studentDOB'),
    },
    {
      title: 'Reg. Number',
      dataIndex: 'regNo',
      width: 150,
      sorter: (a, b) => a.regNo - b.regNo,
      ...this.getColumnSearchProps('regNo'),
    },
    {
        title: 'Reg. Date',
        dataIndex: 'dor',
        width: 140,
        sorter: (a, b) => a.dor.length - b.dor.length,
        ...this.getColumnSearchProps('dor'),
    },
    {
      title: 'Admission Date',
      dataIndex: 'doa',
      width: 170,
      sorter: (a, b) => a.doa.length - b.doa.length,
      ...this.getColumnSearchProps('doa'),
    },
    {
      title: 'Session',
      dataIndex: 'session',
      width: 110,
      sorter: (a, b) => a.session.length - b.session.length,
      ...this.getColumnSearchProps('session'),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 100,
      filters: [
        {
          text: 'Male',
          value: 'Male',
        },
        {
          text: 'Female',
          value: 'Female',
        }
      ],
      sorter: (a , b) => a.gender.length - b.gender.length,
      ...this.getColumnSearchProps('gender'),
    },
    {
      title: 'S. NIC',
      dataIndex: 'studentNIC',
      width: 125,
      sorter: (a, b) => a.studentNIC.length - b.studentNIC.length,
      ...this.getColumnSearchProps('studentNIC'),
    },
    
    {
        title: 'Family Code',
        dataIndex: 'fCode',
        width: 150,
        sorter: (a, b) => a.fCode - b.fCode,
        ...this.getColumnSearchProps('fCode'),
    },
    {
        title: 'Parent Name',
        dataIndex: 'parentName',
        width: 150,
        sorter: (a, b) => a.parentName.length - b.parentName.length,
        ...this.getColumnSearchProps('parentName'),
    },
    {
        title: 'Parent CNIC',
        dataIndex: 'pCNIC',
        sorter: (a, b) => a.pCNIC.length - b.pCNIC.length,
        width: 160,
        ...this.getColumnSearchProps('pCNIC'),
    },
    {
        title: 'Parent Number',
        dataIndex: 'pCellNumber',
        width: 160,
        sorter: (a, b) => a.pCellNumber.length - b.pCellNumber.length,
        ...this.getColumnSearchProps('pCellNumber'),
    },
    {
        title: 'Parent Email',
        dataIndex: 'email',
        width: 160,
        sorter: (a, b) => a.email.length - b.email.length,
        ...this.getColumnSearchProps('email'),
    },
    {
        title: 'Nationality',
        dataIndex: 'nationality',
        width: 120,
        filters: [
          {
            text: 'Pakistan',
            value: 'Pakistan',
          },
          {
            text: 'India',
            value: 'India',
          },
          {
            text: 'Afghanistan',
            value: 'Afghanistan',
          }
        ],
        sorter: (a, b) => a.nationality.length - b.nationality.length,
        onFilter: (value, record) => record.nationality.indexOf(value) === 0,
    },
    {
      title: 'City',
      dataIndex: 'city',
      width: 100,
      filters: [
        {
          text: 'Vehari',
          value: 'Vehari',
        },
        {
          text: 'Lahore',
          value: 'Lahore',
        }
      ],
      sorter: (a, b) => a.city.length - b.city.length,
      onFilter: (value, record) => record.city.indexOf(value) === 0,
      
    }, 
    {
        title: 'Area',
        dataIndex: 'area',
        width: 100,
        filters: [
          {
            text: 'Garden Town',
            value: 'Garden Town',
          },
          {
            text: 'Gulbarg',
            value: 'Gulbarg',
          }
        ],
        sorter: (a, b) => a.area.length - b.area.length,
        ...this.getColumnSearchProps('area'),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        sorter: (a, b) => a.status.length - b.status.length,
    },
    {
        title: 'Leaving Date',
        dataIndex: 'leavingDate',
        width: 140,
        ...this.getColumnSearchProps('leavingDate'),
    },
    {
        title: 'Created Date',
        dataIndex: 'createdDate',
        width: 140,
        ...this.getColumnSearchProps('createdDate'),
    },
    {
        title: 'Created By',
        dataIndex: 'createdBy',
        width: 140,
        sorter: (a, b) => a.createdBy.length - b.createdBy.length,
        ...this.getColumnSearchProps('createdBy'),
    },
    {
        title: 'Operations',
        dataIndex: 'ops',
        width: 300,
        render: (id)=>
          <>
            <Link to='/'><Tag icon={<EyeOutlined />} color="success">View</Tag></Link>
            <Link to='/'><Tag color="processing">Modify</Tag></Link>
            <Link to='/'><Tag icon={<ClockCircleOutlined  />} color="default">History</Tag></Link>
            <Link to='/student/leavingform'><Tag icon={<MinusCircleOutlined  />} color="warning">Leave</Tag></Link>
          </>
    }
  ];
  

  render(){
    const {selectionAllow } = this.props;
    
    return (
      <>
        {selectionAllow? 
        <Table scroll={{ x: 3500, y: 350 }}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={this.Columns}
          dataSource={StudentsListData}/>:
          <Table scroll={{ x: 3500, y: 350 }}
              columns={this.Columns}
              dataSource={StudentsListData}
            />}
      </>
    );
  }


}

export default StudentsTable;