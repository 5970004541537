import {  Layout} from 'antd';
import 'antd/dist/antd.css';


const { Content } = Layout;


export default function ContantArea(props: any){
    return (
        <Layout className="site-layout" >
                <Content style={{ margin: '5px 8px',minHeight: '60vh' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360,minWidth: '600px', position: 'relative', overflow: 'hidden' }}>
                        {props.children}
                    </div>
                </Content>
            </Layout>
        
    )

}