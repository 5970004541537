import { Menu } from "antd";
import {
  ControlOutlined,
  GroupOutlined,
  TeamOutlined,
  PayCircleOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

function SideBarMenu(props: any): any {
  return (
    <Menu
      theme={props.themeState.theme}
      defaultSelectedKeys={["1"]}
      mode="inline"
      defaultOpenKeys={["ResourcesMaterial"]}
    >
      {/* <SubMenu
        key="dashboard"
        icon={<GroupOutlined style={{ fontSize: 20 }} />}
        title={<span style={{ fontSize: 16 }}>DashBoard</span>}
      ></SubMenu>
      <SubMenu
        key="student"
        icon={<TeamOutlined style={{ fontSize: 20 }} />}
        title={<span style={{ fontSize: 16 }}>Students</span>}
      >
        <Menu.Item key="6">
          <Link
            to="/student/registration"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            New Registration
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link
            to="/student/reg_list"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            Registration List
          </Link>
        </Menu.Item>
        <Menu.Item key="8">
          <Link
            to="/student/admission"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            New Admission
          </Link>
        </Menu.Item>
        <Menu.Item key="9">
          <Link
            to="/student/students"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            Student List
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="Settings"
        icon={<ControlOutlined style={{ fontSize: 20 }} />}
        title={<span style={{ fontSize: 16 }}>Settings</span>}
      >
        <SubMenu
          key="fee"
          icon={<PayCircleOutlined style={{ fontSize: 20 }} />}
          title={
            <span style={{ fontSize: 16 }}>
              <Link
                to="/settings/fee"
                style={{
                  textDecoration: "none",
                  color: props.themeState.color,
                }}
              >
                Fee
              </Link>
            </span>
          }
        >
          <Menu.Item key="11">
            <Link
              to="/settings/fee/fee_head"
              style={{ textDecoration: "none", color: props.themeState.color }}
            >
              Fee Heads
            </Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link
              to="/settings/fee/tamplate/create_template"
              style={{ textDecoration: "none", color: props.themeState.color }}
            >
              Create Fee Templates
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link
              to="/settings/fee/tamplates/list_fee_templates"
              style={{ textDecoration: "none", color: props.themeState.color }}
            >
              Fee Templates List
            </Link>
          </Menu.Item>
        </SubMenu>
      </SubMenu> */}
      <SubMenu
        key="ResourcesMaterial"
        icon={<GroupOutlined style={{ fontSize: 20 }} />}
        title={<span style={{ fontSize: 16 }}>Resources</span>}
      >
        <Menu.Item key="14">
          <Link
            to="/resourcesmaterial/resourcesmaterialadmin"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            Admin
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link
            to="/resourcesmaterial/resourcesmaterialacademic"
            style={{ textDecoration: "none", color: props.themeState.color }}
          >
            Academic
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
}

const mapStatetoProps = (state: any): any => {
  return {
    themeState: state,
  };
};

export default connect(mapStatetoProps)(SideBarMenu);
