
import { Layout } from 'antd';

import 'antd/dist/antd.css';
import ContantArea from '../ContantArea/ContantArea';
import SideBarMenu from '../Menus/SideBarMenu/SideBarMenu';
import { connect } from 'react-redux';
const { Sider } = Layout;

function Sidebar(props: any){
   

   
    return (
        <Layout style={{ minHeight: '100vh' }}  >
            <Sider collapsible className='pt-2' width={300}
                    style={{position: 'relative', zIndex: 1000}}
                    theme={props.themeState.theme}>
                <SideBarMenu/>
            </Sider>
            <ContantArea>
                {props.children}
            </ContantArea>
        </Layout>
      );
}


const mapStatetoProps = (state: any): any=>{
    return{
        themeState: state
    }
}

export default connect(mapStatetoProps)(Sidebar);