import { Typography, Modal, Form, Input, Tooltip, Divider, Tag, Button } from "antd";
import { PhoneOutlined, MailOutlined, IdcardOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import MultiTaskingComboBox from "../../../../Components/MainComponents/GlobalCustomUI/MultiTaskingComboBox/MultitaskingComboBox";
import { useState } from "react";
import ParentList from "../../SharedComponents/Tables/ParentList";

const { TextArea } = Input;
const { Text, Title } = Typography;


export default function ParentalForm(props: any) {
    const [form] = Form.useForm();
    const [sParentAreaVisible, setSParentAreaVisible] = useState(false);
    const [parentListVisible, setParentListVisible] = useState(false);
    return (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off">
            <div className="container-fluid p-4" style={{ backgroundColor: 'white' }}>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Student ID"
                            label="Student ID"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Registration ID"
                            label="Registration ID"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text code>R-Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        
                    </div>
                    <div className="ccol-lg-2 col-md-2 col-sm-12 col-12">
                        <Button onClick={()=>setParentListVisible(true)} className="allTextInput">Attech to Parent</Button>
                        <Modal width={1080} title="Parent List" visible={parentListVisible}  onCancel={()=>setParentListVisible(false)}>
                            <ParentList />
                        </Modal>
                    </div>
                </div>
                {
                    // Row 2
                }
                <div className="row" >
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12" >
                        <Form.Item
                            name="Parent First Name"
                            label="Parent First Name"
                            rules={[{ required: true }, { type: 'string', min: 6 }]}>
                            <Input className="allTextInput" placeholder="Parent First Name" suffix={"FN"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Parent Last Name"
                            label="Parent Last Name"
                            rules={[{ type: 'string', min: 4, warningOnly: true }]}>
                            <Input className="allTextInput" placeholder="Parent Last Name" suffix={'LN'} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Parent Email"
                            label="@Email"
                            rules={[{ required: true }, { type: 'email', min: 20, max: 100, warningOnly: true }]}>
                            <Input className="allTextInput" placeholder="Email" suffix={<MailOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Cell Number"
                            label={<>Cell Number&nbsp;<PhoneOutlined /></>}
                            rules={[{ required: true }, { type: 'string', min: 7, max: 20, warningOnly: true }]}>
                            <Tooltip title='You just Enter the number after (+923) -> (+923xx-xxxxxxx)'>
                                <NumberFormat placeholder="Mobile Number" className="allTextInput form-control" format="+92-3##-#######" />
                            </Tooltip>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12" >
                        <Form.Item
                            name="Occupation"
                            label="Occupation"
                            rules={[{ type: 'string', min: 6 }]}>
                            <Input className="allTextInput" placeholder="Occupation" suffix={"O"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Identity Number"
                            label={<>CNIC  &nbsp;<IdcardOutlined /></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <NumberFormat placeholder="National Identity" className="form-control allTextInput"
                                format="#####-#######-#"
                            />
                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 3
                }
                <div className="row " >
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Relation"
                            label="Relation"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <MultiTaskingComboBox placeHolder='Select / Add Relation' ListTitle='of Student' data={['Father', 'Mother']} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="City"
                            label="City"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <MultiTaskingComboBox placeHolder='Select / Add City' ListTitle='City' data={['Vehari', 'Multan']} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Area"
                            label="Area"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <MultiTaskingComboBox placeHolder='Select / Add Area' ListTitle='Area' data={['Area 1', 'Area 2']} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">

                    </div>
                </div>
                {
                    // Row 5
                }
                <Divider >Second Parent &nbsp;
                    {sParentAreaVisible ? 
                        <Tooltip title="In case of no Second Parent by click disable Second Parent information gathering area"><Tag style={{cursor: 'pointer'}} onClick={()=>setSParentAreaVisible(!sParentAreaVisible)} color="warning">Disable</Tag></Tooltip>:
                        <Tooltip title="If student have second parent please click to enable Second Parent Information Gathering Area"><Tag style={{cursor: 'pointer'}} onClick={()=>setSParentAreaVisible(!sParentAreaVisible)} color="processing">Enable</Tag></Tooltip>
                    }</Divider>
                {sParentAreaVisible && <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Tooltip title='Select Relation as Deservence'>
                            <Form.Item
                                name="Second Parent Relation"
                                label="Relation"
                                rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                                <MultiTaskingComboBox placeHolder='Select / Add Relation' ListTitle='of Student' data={['Father', 'Mother']} />
                            </Form.Item>
                        </Tooltip>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12" >
                        <Tooltip title='Second Parent Name'>
                            <Form.Item
                                name="Second Parent Name"
                                label="S. Parent Name"
                                rules={[{ required: true }, { type: 'string', min: 6 }]}>
                                    <Input className="allTextInput" placeholder="Second Parent Name" suffix={"FN"} />
                            </Form.Item>
                        </Tooltip>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Second Parent Email"
                            label="@Email"
                            rules={[{ required: true }, { type: 'email', min: 20, max: 100, warningOnly: true }]}>
                            <Input className="allTextInput" placeholder="Email" suffix={<MailOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item
                            name="Cell Number"
                            label={<>Cell Number&nbsp;<PhoneOutlined /></>}
                            rules={[{ required: true }, { type: 'string', min: 7, max: 20, warningOnly: true }]}>
                            <Tooltip title='You just Enter the number after (+923) -> (+923xx-xxxxxxx)'>
                                <NumberFormat placeholder="Cell Number" className="form-control allTextInput" format="+92-3##-#######" />
                            </Tooltip>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12" >
                        <Form.Item
                            name="S. Occupation"
                            label="Occupation"
                            rules={[{ type: 'string', min: 6 }]}>
                            <Input className="allTextInput" placeholder="Occupation" suffix={"O"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-12">
                        <Form.Item  name="S. Identity Number" label={<>CNIC  &nbsp;<IdcardOutlined /></>} rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <NumberFormat placeholder="National Identity" className="form-control allTextInput" format="#####-#######-#" />
                        </Form.Item>
                    </div>
                </div>}
                {
                    // Row 5
                }
                <div className="row">
                    <TextArea placeholder="Add Details About the New admission for example any history " className="w-100 m-4 allTextInput" rows={4} />
                </div>
            </div>
        </Form>
    )

}