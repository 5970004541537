import { Descriptions,  Button } from 'antd';
 export default function DisplayFormData(){
     return(
        <Descriptions  title="New registration" bordered style={{backgroundColor: 'white'}} className='p-4 mb-2' >
            <Descriptions.Item span={3} label="Personal Information">
                Name: Muhammad Umer Ibrahim
                <br />
                Father:  Muhammad Azam
                <br />
                Gender: Male
                <br />
                CNIC/B-Form: 3666564589454154
                <br />
                Comments: here 
                <br />    
            </Descriptions.Item >
            <Descriptions.Item span={3} label="Location Information">
                City: Vehari
                <br />
                Address:  1149/1 G-block
                <br /> 
                Email: umerawan101@outlook.com 
                <br />
                Phone: 03156969200
                <br />
                Comments: here 
                <br />    
            </Descriptions.Item>
            <Descriptions.Item label="Acadamic Information" span={3}>
                Reg. Data: 22/12/2021
                <br />
                Entry Test:  Yes at (25/12/2021)
                <br /> 
                Class Plan: 6A
                <br />
                Session: Fall 2021
                <br />
                Comments: Cooments Here 
                <br />    
            </Descriptions.Item>
            <Descriptions.Item label="Yearly Fee Plan">
                Reguler Reguler Fee Plan
            </Descriptions.Item>
            <Descriptions.Item label="Fee Head">
                Sports Fee: 1000
                <br />
                Monthly Fee:  25000
                <br /> 
                Cantten Fee: 2500
                <br />
                Extra Criculem Activity: 1000
                <br />
                Total Semester Fee: 200000
                <br />    
            </Descriptions.Item>
            <Descriptions.Item label="Available Attachments">
                Parent Cnic: Y
                <br />
                Form-B:  Y
                <br /> 
                Certificates: <span style={{color: 'red'}}>N</span>
                
                <br /> 
            </Descriptions.Item>
            <Descriptions.Item label="Operations">
                <Button type='primary'>Print Challan</Button>
                <Button type='primary' >Print Information</Button>
                
            </Descriptions.Item>
      </Descriptions>
     )
 }