export const ClassPlanData = {
        name: 'Paly Group (Red)',
        terms: [
            {
                termName: 'Mid Semester',
                CompulsorySubjects:{
                    defaultSubjects: [
                        'Urdu', 'English'
                    ],
                    subjects: [
                        'Urdu', 'English', 'Math', 'Science'
                    ]
                },
                Elective: {
                    defaultSubjects: [
                        'Physics', 'Computer'
                    ],
                    subjects: [
                        'Physics', 'Computer', 'NetWorking', 'Algol'
                    ]
                }
            },
            {
                termName: 'Final Semester',
                CompulsorySubjects:{
                    defaultSubjects: [
                        'Arabic', 'Islamiyat'
                    ],
                    subjects: [
                        'Urdu', 'Arabic', 'Islamyat', 'Science'
                    ]
                },
                Elective: {
                    defaultSubjects: [
                        'Criminology', 'Computer'
                    ],
                    subjects: [
                        'Criminology', 'Computer', 'Austrology', 'Algol'
                    ]
                }
            }
        ]
    }