import { Radio, Typography, Form, Select, Input, DatePicker } from "antd";
import { useState } from "react";

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Option } = Select;

export default function AcadamicForm(props: any) {
    const [form] = Form.useForm();
    const [EntryDateCtrlVisible, setEntryDateCtrlVisible] = useState<boolean>(false);
    var entryDateCtrlVisibilityHandler = (event: any) => {
        event.target.value === 1 ? setEntryDateCtrlVisible(true) : setEntryDateCtrlVisible(false);
    }
    return (
        <Form
            form={form}
            layout="vertical"
            autoComplete="on"
        >
            <div className="container-fluid p-4" style={{backgroundColor: 'white'}}>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Registration Number"
                            label="Registration Number"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text  code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Registration Date"
                            label="Registration Date"
                            rules={[{ required: true }, { type: 'date', min: 6, warningOnly: true }]}
                        >
                            <DatePicker  className='w-100 allTextInput' />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Take Entry Test"
                            label="Take Entry Test"
                            rules={[{ required: true }, { type: 'number' }]}
                        >
                            <Radio.Group className="pt-1" style={{borderRadius: '5px',boxShadow: '5px 5px 2px #e5e2e2'}} name="Take Entry Test" defaultValue={2} onChange={entryDateCtrlVisibilityHandler} >
                                <Radio.Button value={1}>Yes</Radio.Button>
                                <Radio.Button value={2}>No</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-11 col-11 m-2">
                        {EntryDateCtrlVisible &&
                            <Form.Item
                                name="Entry Test Date"
                                label="Entry Test Date"
                                rules={[{ required: true }, { type: 'date', warningOnly: true }]}
                            >
                                <DatePicker className='w-100 allTextInput' />
                            </Form.Item>}
                    </div>
                </div>
                {
                    // Row 2
                }
                <div className="row " >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Class Plan"
                            label="Class Plan"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select optionFilterProp="children" showSearch placeholder="Class Plan" className="w-100 allTextInput"  >
                                <Option value={1}>6A</Option>
                                <Option value={2}>7B</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Session"
                            label="Session"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select optionFilterProp="children" showSearch placeholder="Session" className="w-100 allTextInput"  >
                                <Option value={1}>2021-2022</Option>
                                <Option value={2}>2020-2021</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-5 col-md-7 col-sm-11 col-11 m-2 ">
                        <Form.Item
                                name="Comment"
                                label="Comment"
                                rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <TextArea placeholder="Add Details About this New admission for example any history " className="w-100 allTextInput" rows={4} /> 
                        </Form.Item>
                    </div>
                </div>
            </div>
        </Form>
    )

}