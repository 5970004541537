import { Slider, Select, Row } from 'antd';
import { Button } from 'antd/lib/radio';
import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import FileUpload from '../../Upload/FileUpload';
import CameraModal from '../WebCame/Cameramodal';
import GetCroppedImg from './CroppedImage'
import AddEffect from './AddEffect';

const { Option } = Select;

const ImageProcessing = ({receiveCapturedImage}:any) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [contrast, setcontrast] = useState(1)
    const [brightness, setbrightness] = useState(1)
    const [ratio, setRatio] = useState(16/9)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState<any>(null)
    const [srcFile, setSrcFile] = useState(null);
    const [isReadyEffectedImage, setIsReadyEffectedImage] = useState(false);
    const [effectedImage, setEffectedImage] = useState(null)

    const UpdateEffects = useCallback((img: any)=>{
        setIsReadyEffectedImage(false)
        setEffectedImage(img)
        receiveCapturedImage(img)
    },[receiveCapturedImage])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      }, [])

    const saveCroppedImage = useCallback(async () => {
        try {
          const croppedImage = await GetCroppedImg(
            srcFile,
            croppedAreaPixels,
            rotation
          )
          setCroppedImage(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }, [croppedAreaPixels, rotation, srcFile])

    return (
      <div className='row' style={{height: '600px'}}>
            <div className='col-lg-3'>
                <div className='col'>
                    <CameraModal receiveCapturedImage={setSrcFile} name='Use camera'/>
                    <FileUpload  srcImage={setSrcFile} currentUploadName='Profile Picture' />
                    <strong>Zoom Image</strong>
                    <Slider defaultValue={1} min={1} max={4} onChange={(val)=>{setZoom(val)}} />
                </div>
                <div className='col'>
                    <strong>Rotate Image</strong><br/>
                        <Slider defaultValue={0} max={360} onChange={(val)=>{setRotation(val)}}/>
                    <strong>Rotate Image (Degree)</strong><br/>
                    <div className='row ml-2 mt-1' >
                        <Button style={{fontSize: '0.8em'}} onClick={()=>{setRotation(90)}}>90<sup>o</sup></Button>
                        <Button style={{fontSize: '0.8em'}} onClick={()=>{setRotation(180)}}>180<sup>o</sup></Button>
                        <Button style={{fontSize: '0.8em'}} onClick={()=>{setRotation(270)}}>270<sup>o</sup></Button>
                        <Button style={{fontSize: '0.8em'}} onClick={()=>{setRotation(360)}}>360<sup>o</sup></Button>
                    </div>
                </div>
                <div className='col mt-4'>
                    <strong>Ratio</strong>
                    <Select defaultValue="16/9" style={{ width: '100%' }} onChange={(val)=>{setRatio(Number(val))}}>
                        <Option value={16/18}>16/18</Option>
                        <Option value={16/12}>16/12</Option>
                        <Option value={16/9}>16/9</Option>
                        <Option value={2/1}>2/1</Option>
                        <Option value={1/1}>1/1</Option>
                    </Select>
                </div>
                <div className='col mt-4'>
                    <strong>Brightness</strong><br/>
                    <Slider defaultValue={1} max={5} onChange={(val)=>{setbrightness(val)}}/>
                    <strong>Contrast</strong><br/>
                    <Slider defaultValue={1} max={5} onChange={(val)=>{setcontrast(val)}}/>
                    <Button onClick={saveCroppedImage} >Crop</Button>
                    <Button onClick={()=>setIsReadyEffectedImage(true)} >Add Effects</Button>
                    
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='col '>
                        <Row>
                            <h6>Cropped Image</h6>
                        </Row>
                        <Row>
                            {croppedImage && <img src={croppedImage} alt='ok' width={'300px'} height={200}/>}
                        </Row>
                </div>
                <div className='col'>
                    <Row>
                        <h6>Final Image</h6>
                    </Row>
                    <Row>
                        {effectedImage && <img src={effectedImage} alt='ok' width={'300'} style={{margin: '20px'}} />}
                    </Row>
                </div>
                <div className='d-none'>
                    {isReadyEffectedImage && <AddEffect brightness={brightness}  contrast={contrast} sourceImage={croppedImage} outputImage={UpdateEffects} />}
                </div>
            </div>
            <div className='col-lg-4'>
                {srcFile && <Cropper
                    image={srcFile}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    aspect={ratio}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />}
            </div>
      </div>
    )
}

export default ImageProcessing;