import { message, Image, Select, Radio, Input, Button, DatePicker } from "antd";
import { DownloadOutlined, MailOutlined } from '@ant-design/icons';
import RegisteredAdmission from "../../SharedComponents/Modals/RegisteredAdmissionModal";
import { useCallback, useState } from 'react';
import ImageProcessingModal from "../../../../Components/MainComponents/GlobalCustomUI/ImageProcessModule/ImageProcessing/ImageProcessingModal";
import { Typography, Form } from "antd";
import { IdcardOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";

const { Option } = Select;
const { TextArea } = Input;
const { Text, Title } = Typography;



export default function PersonalForm(props: any) {
    const [visible, setVisible] = useState(false);
    const [visibleProfPicModal, setVisibleProfPicModal] = useState(false);
    const [profileImage, setProfileImage] = useState();

    const pPModalVisibilityHandler = useCallback(() => {
        setVisibleProfPicModal(!visibleProfPicModal);
    }, [visibleProfPicModal])

    const updateProfile = useCallback((img) => {
        setProfileImage(img);
    }, [])

    const [form] = Form.useForm();

    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <div className="container-fluid p-4 m-1 workingArea" >
                {
                    // Row 1 start
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Student ID"
                            label="Student ID"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="Registration ID"
                            label="Registration ID"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text code>R-Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 1 Closed
                    //ROw 2 Start
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8 m-2">
                        <Button style={{height: 50}}  onClick={pPModalVisibilityHandler} shape="round" >
                             Profile Picture
                        </Button>
                        <ImageProcessingModal  ProfileImage={updateProfile} visibility={visibleProfPicModal} setVisibility={pPModalVisibilityHandler} />
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        {profileImage && <Image
                                width={200}
                                src={profileImage}
                                alt="No Image" />} 
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Button style={{height: 50}}  shape="round" onClick={() => setVisible(true)} icon={<DownloadOutlined />}>
                            Registered Admissions 
                        </Button>
                        <RegisteredAdmission  visible={visible} setVisible={setVisible} />
                    </div>
                </div>
                {
                    //Row 2 closed
                    //Row 3 Start
                }
                <div className="row" >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Student First Name"
                            label="Student First Name"
                            rules={[{ required: true }, { type: 'string', min: 6 }]}
                        >
                            <Input className="allTextInput" placeholder="First Name" suffix={"FN"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Student Last Name"
                            label="Student Last Name"
                            rules={[{ type: 'string', min: 4, warningOnly: true }]}
                        >
                            <Input className="allTextInput" placeholder="Last Name" suffix={'LN'} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Student Email"
                            label="Student @Email"
                            rules={[{ type: 'email', min: 20, max: 100, warningOnly: true }]} >
                            <Input className="allTextInput" placeholder="Email" suffix={<MailOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-11 col-11 m-2">
                        <Form.Item
                            name="Gender"
                            label="Select Gender"
                            rules={[{ required: true }, { type: 'number' }]}>
                            <Radio.Group name="Gender" defaultValue={1} className="pt-2" style={{ borderRadius: '5px', boxShadow: '5px 5px 2px #e5e2e2' }}>
                                <Radio.Button value={1}>Male</Radio.Button>
                                <Radio.Button value={2}>Female</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 3 Closed
                    //Row 4Start
                }
                <div className="row mt-2">
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Date of Birth"
                            label={<>Date of Birth &nbsp;<IdcardOutlined/></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <DatePicker className='allTextInput w-100' />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Religion"
                            label={<>Religion &nbsp;<IdcardOutlined/></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <Select placeholder="Religion" className="allTextInput"  >
                                <Option value="1">Musim</Option>
                                <Option value="2">Non Muslim</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2">
                        <Form.Item
                            name="Select Language"
                            label={<>Mother Toung &nbsp;<IdcardOutlined/></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <Select placeholder="Language" className="allTextInput"  >
                                <Option value="muslim">English</Option>
                                <Option value="non-muslim">Urdu</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-11 col-11 m-2">
                        <Form.Item
                            name="Identity Number"
                            label={<>Student CNIC / B-Form &nbsp;<IdcardOutlined/></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                             <NumberFormat placeholder="National Identity" className="form-control allTextInput"
                                    format="#####-#######-#"
                            />
                        </Form.Item>
                    </div>
                </div>
                {
                    //Row 4 closed
                    //Row 5 Start
                }
                <div className="row mt-2">
                    <TextArea placeholder="Add Details About this New admission for example any history " className="allTextInput w-100 m-4" rows={4} />
                </div>
            </div>
        </Form>
    )

}