

import Sidebar from "../../Navigations/Sidebar/Sidebar";
import Topbar from "../../Navigations/Topbar/Topbar";

export default function MainLayout(props: any): any{
    

    return(
        <>
            <Topbar />
            <Sidebar>
                {props.children}
            </Sidebar>
        </>
    )
}