import {Row, Col } from 'antd';
import React from 'react';
import 'antd/dist/antd.css';
import { Steps, Button, message } from 'antd';
import PersonalForm from '../Froms/PersonalForm';
import AcadamicForm from '../Froms/AcadamicForm';
import ParentalForm from '../Froms/ParentalForm';
import MedicalForm from '../Froms/MedicalFrom';
import  '../../../../GlobalCSS/Global.css'
import FeesForm from '../../SharedComponents/Forms/FeesFrom';
import DisplayFormData from '../../SharedComponents/DisplayData/DisplayformData';


const { Step } = Steps;

const steps = [
  {
    title: 'Personal',
    content: <PersonalForm/>,
  },
  {
    title: 'Acadamic',
    content: <AcadamicForm/>,
  },
  {
    title: 'Parental',
    content: <ParentalForm/>,
  },
  {
    title: 'Medical',
    content: <MedicalForm />,
  },
  {
    title: 'Fees',
    content: <FeesForm formOf={'admission'}/>,
  },
  {
    title: 'View',
    content: <DisplayFormData/>,
  }
];

const AdmissionFormSteps = () => {
  const [current, setCurrent] = React.useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <h4 className='h4 text-center mb-4'>Student Admission Form</h4>
      <Row className='m-4'>
        <Col span={20} offset={2} className='p-4 shadow' >
        <Steps current={current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        </Col>
      </Row>
      <div className="steps-content">
        {steps[current].content}
      </div>
      <Row justify='end' className="steps-action m-2">
        {current < steps.length - 1 && (
          <Button className='m-1' style={{borderRadius: '5px'}} type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button className='m-1' style={{borderRadius: '5px'}} type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button className='m-1' style={{borderRadius: '5px'}} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </Row>
    </>
  );
};

export default AdmissionFormSteps;