import React from 'react';
import { Table } from 'antd';
import { RegisteredStudentListData } from '../../../../StaticDataSource/StudentModule/DataRegistrationProcess';
import { Link } from 'react-router-dom';

const columns = [
  {
    title: 'Registration No.',
    dataIndex: 'regNo',
  },
  {
    title: 'Class Name',
    dataIndex: 'className',
    filters: [
      {
        text: 'Play Group Red',
        value: 'Play Group (Red)'
      },
      {
        text: 'Play Group Blue',
        value: 'Play Group (Blue)'
      }
    ],
    onFilter: (value, record) => record.city.indexOf(value) === 0,
    sorter: (a, b) => a.className.length - b.className.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Student Name',
    dataIndex: 'studentName',
    sorter: (a, b) => a.studentName.length - b.className.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Registration Date',
    dataIndex: 'regDate',
    sorter: (a, b) => a.regDate.length - b.regDate.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Session',
    dataIndex: 'session',
    sorter: (a, b) => a.session.length - b.session.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Parent Cell',
    dataIndex: 'parentCell',
    sorter: (a, b) => a.parentCell.length - b.parentCell.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Address',
    dataIndex: 'address',
    sorter: (a, b) => a.address.length - b.address.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'City',
    dataIndex: 'city',
    filters: [
      {
        text: 'Vehari',
        value: 'Vehari',
      },
      {
        text: 'Lahore',
        value: 'Lahore',
      }
    ],
    onFilter: (value, record) => record.city.indexOf(value) === 0,
    sorter: (a, b) => a.city.length - b.city.length,
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Comments',
    dataIndex: 'comments',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    render: (text) => <Link to={`/${text}`} style={{color: 'gray'}}>modify</Link>,
  }
];


const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record) => ({
    disabled: record.isDisabled === true, 
    name: record.studentName,
  }),
};

const RegisteredStudentTable = (props) => {
  const {selectionAllow } = props;
  return (
    <div>
      {selectionAllow? <Table scroll={{ x: 1500, y: 300 }}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={RegisteredStudentListData}
      />:<Table scroll={{ x: 1500, y: 350 }}
            columns={columns}
            dataSource={RegisteredStudentListData}
          />}
    </div>
  );
};

export default RegisteredStudentTable;