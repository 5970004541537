

import FeeHeadList from './FeeHeadList.tsx/FeeHeadList'
import './Styles/Styles.css'

export default function FeeHead() {
    return (
        <>
            <div className='container-fluid workingArea p-2'>
                <FeeHeadList />
            </div>
        </>

    )
}