import React, { useState } from 'react';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button } from 'antd';
import 'antd/dist/antd.css';
import {  Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

interface ITblRow {
    key: string;
    id: number;
    name: string;
    feeType: string;
    feeMonth: string;
    printOrder: number;
    discountPriority: number;
    isDiscountable: string;
    arithmeticOps: string;
    description: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | 'boolean';
    record: ITblRow;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const FeeHeadList = () => {
    const [searchProps,setSearchProps] = useState({
        searchText: '',
        searchedColumn: ''
    })
    const [form] = Form.useForm();
    const [data, setData] = useState<ITblRow[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const [sKey, setSKey] = useState('250525');
    const [oldData, setOldData] = useState<ITblRow[]>([]);
    const [addHeadBtnVisibility, setAddHeadBtnVisibility] = useState(true)



    const isEditing = (record: ITblRow) => record.key === editingKey;

    const edit = (record: Partial<ITblRow> & { key: React.Key }) => {
        form.setFieldsValue({ name: '', ...record });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
        ///retake old because of cancelation
        setData(oldData);
        setAddHeadBtnVisibility(true)
    };
    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {

                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space direction="vertical">
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}>
                            Search
                        </Button>
                        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                    </Space>
                    <Space>
                        <Button size="small"
                            onClick={() => alert('Go back to default Record where the start')}>
                            Default Back
                        </Button>
                        <Button type="link" size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                setSearchProps({
                                    searchText: selectedKeys[0],
                                    searchedColumn: dataIndex,
                                });
                            }}>
                            Filter
                        </Button>
                    </Space>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible: any) => {
            if (visible) {
                setTimeout(() => '', 100);
            }
        },
        render: (text: any) =>
            searchProps.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchProps.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''} />
            ) : (
                text
            ),
    });
    const handleAddNewRow = ()=>{
        setAddHeadBtnVisibility(false);
        setOldData(data);
        var KeyasNum = +sKey;
        KeyasNum = KeyasNum + 1;
        const EmptyRow: ITblRow = {
            key: KeyasNum.toString(),
            id: 260001,
            feeMonth: 'Jan',
            feeType: 'Monthly',
            printOrder: 6,
            discountPriority: 1,
            isDiscountable: 'Not Allow',
            name: 'Extra Cri. Fee',
            arithmeticOps: '+',
            description: 'test Idea'
        }
        
        setSKey(KeyasNum.toString());
        setData([EmptyRow, ...data]);
        edit(
            {
                key: KeyasNum.toString(),
                id: 260001,
                feeMonth: 'Jan',
                feeType: 'Monthly',
                printOrder: 6,
                discountPriority: 1,
                isDiscountable: 'Not Allow',
                name: 'Extra Cri. Fee',
                arithmeticOps: '+',
                description: 'test Idea'
            })
    }

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as ITblRow;

            const newData = [...data];
            const index = newData.findIndex(item => key === item.key);
            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setData(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey('');
            }
            setAddHeadBtnVisibility(true)
        } 
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '5%',
            sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '15%',
            editable: true,
            ...getColumnSearchProps('name')
        },
        {
            title: 'Fee Type',
            dataIndex: 'feeType',
            width: '10%',
            editable: true,
            ...getColumnSearchProps('feeType')
        },
        {
            title: 'Fee Month',
            dataIndex: 'feeMonth',
            width: '10%',
            editable: true,
            sorter: (a: any, b: any) => a.feeMonth.length - b.feeMonth.length,
        },
        {
            title: 'Print Order',
            dataIndex: 'printOrder',
            width: '10%',
            editable: true,
            sorter: (a: any, b: any) => a.printOrder - b.printOrder,
        },
        {
            title: 'Discount Adj. Priority',
            dataIndex: 'discountPriority',
            width: '10%',
            editable: true,
            sorter: (a: any, b: any) => a.discountPriority - b.discountPriority,
        },
        {
            title: 'Arithmetic Ops',
            dataIndex: 'arithmeticOps',
            width: '10%',
            editable: true,
            sorter: (a: any, b: any) => a.arithmeticOps.length - b.arithmeticOps.length,
        },
        {
            title: 'Allow Discount',
            dataIndex: 'isDiscountable',
            width: '10%',
            editable: true,
            sorter: (a: any, b: any) => a.isDiscountable.length - b.isDiscountable.length,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '10%',
            editable: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_: any, record: ITblRow) => {
                const editable = isEditing(record);
                return editable && (
                    <span>
                        <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
                            Save
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                            <a href='/'>Cancel</a>
                        </Popconfirm>
                    </span>
                
                );
            },
        },
    ];
    

    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ITblRow) => ({
                record,
                inputType: col.dataIndex === 'isDiscountable' ? 'boolean' : col.dataIndex === 'discountPriority' || col.dataIndex === 'printOrder' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchProps({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });

    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchProps({ ...searchProps, searchText: '' });
    };
   
    
    return (
        <Form form={form} component={false}>
            {addHeadBtnVisibility && <Button onClick={handleAddNewRow} type='primary'>Add New Head</Button>}
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};


export default FeeHeadList;