import { Switch, Space, Row, Col, Layout, Popover, Input } from "antd";
import "antd/dist/antd.css";
import ProfileDropDown from "../Menus/TopBarManus/ProfileDropDown";
import Logo from "../../../../Assets/Images/logo.png";
import NewNotifications from "../../Notifications/NewNotifications/NewNotifications";
import { SearchOutlined } from "@ant-design/icons";
import GlobalSearch from "../../GlobalSearch/Globalsearch";
import { connect } from "react-redux";
import { switchTheme } from "../../../../Store/Theme/Actions/Actions";
import { BellOutlined } from "@ant-design/icons";
import "./Topbar.css";
import { useCallback, useState } from "react";
const { Header } = Layout;

function Topbar(props: any): any {
  //Popover visibility handling
  const [isNotifyDDVisible, setNotifyDDVisible] = useState(false);

  const NotificationDDVisibilityHandler = useCallback(() => {
    setNotifyDDVisible(!isNotifyDDVisible);
  }, [isNotifyDDVisible]);

  ///Popover Visibility handling closed

  const themeHandler = () => {
    props.switchTheme(props.themeState.theme);
  };

  return (
    <Header
      className="shadow"
      style={{ backgroundColor: props.themeState.backcolor, padding: 0 }}
    >
      <Row style={{ height: "64px" }} justify="space-around">
        <Col
          span={6}
          flex="150px"
          style={{ color: props.themeState.color, height: "64px" }}
        >
          <div className="container2">
            <div>
              <img src={Logo} className="iconDetails" alt="Logo" />
            </div>
            <div style={{ marginLeft: "60px", marginTop: "0.3em" }}>
              <h6 style={{ color: props.themeState.color, margin: "0px" }}>
                NGSPortal
              </h6>
              {/* <div style={{fontSize:'.8em', lineHeight: 1}} className='float-left'>Branch Name</div> */}
            </div>
          </div>
        </Col>
        <Col span={4} className="hideforShortScreen">
          <Popover
            content={GlobalSearch}
            title="Search Results"
            placement="bottomRight"
          >
            <Space>
              <SearchOutlined
                style={{ color: props.themeState.color, fontSize: "24px" }}
              />
              <Input
                placeholder="Search"
                style={{
                  borderRadius: "5px",
                  minWidth: 150,
                  backgroundColor: props.themeState.backcolor,
                  color: props.themeState.color,
                }}
              />
            </Space>
          </Popover>
        </Col>
        <Col span={13} style={{ textAlign: "right" }}>
          <Space size={30}>
            <Popover
              trigger="click"
              visible={isNotifyDDVisible}
              onVisibleChange={NotificationDDVisibilityHandler}
              content={NewNotifications}
              title="Notifications"
              placement="bottom"
            >
              <p style={{ color: props.themeState.color }}>
                <BellOutlined style={{ fontSize: "1.2em" }} />
              </p>
            </Popover>
            <ProfileDropDown />
            <Switch
              defaultChecked
              size="small"
              onChange={themeHandler}
              style={{
                backgroundColor: props.themeState.color,
                color: props.themeState.backcolor,
              }}
              className="mb-3"
            />
          </Space>
        </Col>
      </Row>
    </Header>
  );
}

const mapStatetoProps = (state: any): any => {
  return {
    themeState: state,
  };
};

const dispatchActions = (dispatch: any): any => {
  return {
    switchTheme: (themeType: string) => dispatch(switchTheme(themeType)),
  };
};

export default connect(mapStatetoProps, dispatchActions)(Topbar);
