import Webcam from "react-webcam";
import React, { useState } from "react";

const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };
  
  const CaptureImage = ({receiveCapturedImage}: any) => {

    const webcamRef = React.useRef<any>(null) ;
    const [ capImg, setCapImage ] = useState(null);
  
    const capture = React.useCallback(
      () => {
        const imageSrc = webcamRef.current.getScreenshot() ;
        setCapImage(imageSrc);
        receiveCapturedImage(capImg)
      },
      [webcamRef, capImg, receiveCapturedImage]
    );
  
    return (
      <>
        <div className="row">
          <div className="col-lg-6"><h3 style={{textAlign: 'left'}}>Your Camera</h3></div>
          <div className="col-lg-6"><h3 style={{textAlign: 'right'}}>Captured Image</h3></div>
        </div>
        <div className="row">
          <div className="col-lg-6 ">
                <Webcam
                style={{alignSelf: 'center'}}
                audio={false}
                height={480}
                ref={webcamRef}
                screenshotFormat="image/png"
                width={820}
                videoConstraints={videoConstraints}
              />
          </div>
          <div className="col-lg-6" style={{textAlign: 'center', verticalAlign: 'center', display: "flex", justifyContent: 'center'}}>
              {capImg && <img style={{marginLeft: 'auto',marginRight: 0, display: 'block', marginTop: 10}} width={380} height={220}  src={capImg} alt='Capture Iamge' />}
          </div>
        </div>
        <button className="btn btn-primary" onClick={capture}>Capture photo</button>
      </>
    );
  };
 export default CaptureImage;
