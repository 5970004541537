import { Button, Typography, Form, Input, Tooltip, Tag } from "antd";
import { PhoneOutlined, MailOutlined, IdcardOutlined, EnvironmentOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import MultiTaskingComboBox from "../../../../Components/MainComponents/GlobalCustomUI/MultiTaskingComboBox/MultitaskingComboBox";
import { useState } from "react";
import Modal from "antd/lib/modal/Modal";
import ParentList from "../../SharedComponents/Tables/ParentList";
import Siblings from "../../SharedComponents/Tables/Sibling";

const { TextArea } = Input;
const { Text, Title } = Typography;

export default function ParentalForm(props: any) {
    const [form] = Form.useForm();
    const [parentListVisible, setParentListVisible] = useState(false);
    const [SiblingsListVisible, setSiblingsListVisible] = useState(false);

    return (
        <Form
            form={form}
            layout="vertical"
            autoComplete="off"
        >
            <div className="container-fluid p-4" style={{ backgroundColor: 'white' }}>
                {
                    // Row 1 
                }
                <div className="row">
                    <div className="col-md-3 col-sm-8 m-2">
                        <Form.Item
                            name="reg"
                            label="Registration Number"
                            rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}>
                            <Title level={4}><Text code>Fall-2022-001</Text></Title>
                        </Form.Item>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Button style={{height: 40}} onClick={() => setParentListVisible(true)} className="allTextInput">Attach to Parent</Button>
                        <Modal width={1080} title="Parent List" visible={parentListVisible} onCancel={() => setParentListVisible(false)} key={1}>
                            <ParentList />
                        </Modal>
                    </div>
                    <div className="col-md-3 col-sm-8 m-2">
                        <Button style={{height: 40}}  onClick={() => setSiblingsListVisible(true)} className="allTextInput"><Tag color={'#87d068'}>3</Tag> Show Siblings</Button>
                        <Modal key={2} width={400} title="Parent List" visible={SiblingsListVisible} onCancel={() => setSiblingsListVisible(false)}>
                            <Siblings />
                        </Modal>
                    </div>
                </div>
                {
                    // Row 2
                }
                <div className="row" >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Parent First Name"
                            label="Parent First Name"
                            rules={[{ required: true }, { type: 'string', min: 6 }]}>
                            <Input className="allTextInput" placeholder="Parent First Name" suffix={"FN"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Parent Last Name"
                            label="Parent Last Name"
                            rules={[{ type: 'string', min: 4, warningOnly: true }]}
                        >
                            <Input className="allTextInput" placeholder="Parent Last Name" suffix={'LN'} />
                        </Form.Item>

                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Parent Email"
                            label="@Email"
                            rules={[{ required: true }, { type: 'email', min: 20, max: 100, warningOnly: true }]}
                        >
                            <Input className="allTextInput" placeholder="Email" suffix={<MailOutlined />} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-11 col-11 m-2">
                        <Form.Item
                            name="Cell Number"
                            label={<>Cell Number&nbsp;<PhoneOutlined /></>}
                            rules={[{ required: true }, { type: 'string', min: 7, max: 20, warningOnly: true }]}
                        >
                            <Tooltip title='You just Enter the number after (+923) -> (+923xx-xxxxxxx)'>
                                <NumberFormat placeholder="Mobile Number" className="form-control"
                                    format="+92-3##-#######"
                                />
                            </Tooltip>

                        </Form.Item>
                    </div>
                </div>
                {
                    // Row 3
                }
                <div className="row " >
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2" >
                        <Form.Item
                            name="Occupation"
                            label="Occupation"
                            rules={[{ type: 'string', min: 6 }]}
                        >
                            <Input className="allTextInput" placeholder="Occupation" suffix={"O"} />
                        </Form.Item>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-12 m-2 ">
                        <Form.Item
                            name="Identity Number"
                            label={<>CNIC  &nbsp;<IdcardOutlined /></>}
                            rules={[{ required: true }, { type: 'string', min: 13 }]}>
                            <NumberFormat placeholder="National Identity" className="form-control allTextInput"
                                format="#####-#######-#"
                            />
                        </Form.Item>
                    </div>
                    <div className="col-lg-5 col-md-7 col-sm-11 col-11 m-2 ">
                        <div className="row ml-2">
                            <div className="col">
                                <Form.Item
                                    name="City"
                                    label="City"
                                    rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                                    <MultiTaskingComboBox ListTitle='City' data={['Vehari', 'Multan']} />
                                </Form.Item>
                            </div>
                            <div className="col">
                                <Form.Item
                                    name="Area"
                                    label="Area"
                                    rules={[{ required: true }, { type: 'string', warningOnly: true }]}>
                                    <MultiTaskingComboBox ListTitle='Area' data={['B-Block', 'C-Block']} />
                                </Form.Item>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    // Row 4
                }
                <div className="row m-2">
                    <Form.Item
                        name="Address"
                        label="Address"
                        className="w-100"
                        rules={[{ required: true }, { type: 'string', min: 20, max: 100, warningOnly: true }]}>
                        <Input className="allTextInput" placeholder="Your Current Address" suffix={<EnvironmentOutlined />} />
                    </Form.Item>
                </div>
                <div className="row">
                    <TextArea placeholder="Add Details About the New admission for example any history " className="w-100 m-4 allTextInput" rows={4} />
                </div>
            </div>
        </Form>
    )

}