import { useState } from "react";
import FolderSide from "./Views/FolderSide";
import { useLocation } from "react-router-dom";

export default function ResourcesMaterial(props: any) {
  const search = useLocation().search;
  const UserId = new URLSearchParams(search).get("id");
  const [userId, setUserId] = useState(UserId);

  // if (name === "academic") {
  //   setFolderType(1);
  // } else if (name === "admin") {
  //   setFolderType(2);
  // }

  return (
    <>
      {/* <div className="row">
        <div className="col-md-4"> */}
      <FolderSide FolderType={props.FolderType} UserId={userId} />
      {/* </div>
        <div className="col-md-8"></div>
      </div> */}
    </>
  );
}
