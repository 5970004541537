import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

export default function AvatarTopbar(){
  
    return (
        <Avatar size={40} icon={<UserOutlined />} src="https://joeschmoe.io/api/v1/random" /> 
    )
}

