import { Result } from 'antd';
import { Link } from 'react-router-dom';

export default function NoRoute(props: any):any{
    return(
        <Result
            status={props.statusCode}
            title={props.title}
            subTitle={props.subtitle}
            extra={<Link to={props.gotoRoute} >Go Home</Link>}
        />
    );
}