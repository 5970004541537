export const FeePlanData = [
    {
      key: '0',
      serial: '956568',
      id: '3786',
      feename: 'Yearly Fee Decided',
      feetype: 'Yearly',
      feeamount: '250000',
      feeoperation: '+'
    },
    {
      key: '1',
      serial: '78945',
      id: '786786',
      feename: 'Monthly Fee Decided',
      feetype: 'monthly',
      feeamount: '25000',
      feeoperation: '+'
    },
    {
        key: '2',
        serial: '25453153',
        id: '7563',
        feename: 'For 6 Month Fee Decided',
        feetype: 'monthly',
        feeamount: '25000',
        feeoperation: '+'
      },
      {
        key: '3',
        serial: '7456513',
        id: '786786',
        feename: 'Semester Fee Decided',
        feetype: 'Semester',
        feeamount: '200000',
        feeoperation: '+'
      },
      {
        key: '4',
        serial: '897456',
        id: '7867',
        feename: 'Canteen Fee Decided',
        feetype: 'monthly',
        feeamount: '2500',
        feeoperation: '+'
      },
      {
        key: '5',
        serial: '6548687',
        id: '7846',
        feename: 'Stationary Fee Decided',
        feetype: 'stationary',
        feeamount: '10000',
        feeoperation: '+'
      },
      {
        key: '6',
        serial: '8749879',
        id: '1541684',
        feename: 'Sports Fee Decided',
        feetype: 'monthly',
        feeamount: '1000',
        feeoperation: '+'
      },
      {
        key: '7',
        serial: '8974561',
        id: '98584',
        feename: 'Extra Criculem Activity',
        feetype: 'monthly',
        feeamount: '25000',
        feeoperation: '+',
      },
  ]


  interface DataType {
    key: React.Key;
    id: number
    regNo: string;
    className: string;
    studentName: string;
    parentName: string;
    regDate: string;
    session: string;
    parentCell: string;
    address: string;
    city: string;
    comments: string;
    isDisabled: boolean;
    actions: string;
  }

  export const RegisteredStudentListData: DataType[] = [
    {
      key: '1',
      id: 52000,
      regNo: '51564116',
      className: 'Play Group (Blue)',
      studentName: 'Muhammad Ali',
      parentName: 'Amir Ali',
      regDate: new Date().toLocaleDateString(),
      session: 'Fall',
      parentCell: '03156920059',
      address: 'D1 NGS Gulbarg',
      city: 'Lahore',
      comments: 'Yes am here',
      isDisabled: false,
      actions: 'Edit'
    },
    {
      key: '2',
      id: 523501,
      regNo: '746556415',
      className: 'Play Group (Red)',
      studentName: 'Bilal',
      parentName: 'Akram',
      regDate: new Date().toLocaleDateString(),
      session: 'Spring',
      parentCell: '03125688879',
      address: '123 B-Block',
      city: 'Vehari',
      comments: 'Yes am here',
      isDisabled: false,
      actions: 'Edit'
    },
    {
      key: '3',
      id: 520202,
      regNo: '21634568',
      className: 'Play Group (Blue)',
      studentName: 'Allah Dad',
      parentName: 'Haq Dad',
      regDate: new Date().toLocaleDateString(),
      session: 'Fall',
      parentCell: '03156922269',
      address: 'Walton Road, RA Street',
      city: 'Lahore',
      comments: 'Yes am here',
      isDisabled: false,
      actions: 'Edit'
    },
    {
      key: '4',
      id: 520872,
      regNo: '74894231',
      className: 'Play Group (Red)',
      studentName: 'Khan',
      parentName: 'Ali Kahn',
      regDate: new Date().toLocaleDateString(),
      session: 'Fall',
      parentCell: '03122256558',
      address: '123 G-Block ',
      city: 'Vehari',
      comments: 'Yes am here',
      isDisabled: false,
      actions: 'Edit'
    },
  ];