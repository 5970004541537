import { Badge, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import List from "antd/lib/list";

function Notifications():any{
    return (
        <>
            <List>.
                <Badge.Ribbon text={<Button style={{height: 22, width: 80, fontSize: 10}} type="primary" danger>Done</Button>} color={'red'}> 
                    <List.Item>
                            <List.Item.Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                            title={<><a  href="//">go to task </a><Badge style={{textAlign: 'center'}} /></>}
                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                            />
                    </List.Item>
                </Badge.Ribbon>
                
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="/"> Test</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <Badge.Ribbon text={<Button style={{height: 22, width: 80, fontSize: 10}} type="primary" danger>Done</Button>} color={'red'}> 
                    <List.Item>
                            <List.Item.Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                            title={<><a  href="//">go to task </a><Badge style={{textAlign: 'center'}} /></>}
                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                            />
                    </List.Item>
                </Badge.Ribbon>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="/"> Test</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
                <Badge.Ribbon text={<Button style={{height: 22, width: 80, fontSize: 10}} type="primary" >Ok</Button>}> 
                    <List.Item>
                            <List.Item.Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                            title={<><a  href="//">go to task </a><Badge style={{textAlign: 'center'}} /></>}
                            description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                            />
                    </List.Item>
                </Badge.Ribbon>
                <List.Item>
                    <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={<a href="/"> Test</a>}
                    description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                </List.Item>
            </List>
        </>
    );
}

export default Notifications;