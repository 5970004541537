
interface DataType {
    key: React.Key;
    id: number;
    regNo: string;
    studentDOB: string;
    //date of admission
    doa: string;
    gender: string;
    studentNIC: string;
    fCode: number;
    //clasas Name
    cName: string;
    studentName: string;
    parentName: string;
    pCNIC: string;
    //date of registration
    dor: string;
    session: string;
    pCellNumber: string;
    address: string;
    city: string;
    comments: string;
    status: string;
    actions: number;
    nationality: string;
    ops: string,
    email: string,
    area: string,
    leavingDate: string;
    createdDate: string,
    createdBy: string
}
  
export const StudentsListData: DataType[] = [
    {
      key: '1',
      id: 253336,
      regNo: '2566396536',
      cName: 'Play Group (Red)',
      studentName: 'Haq Dad',
      parentName: 'Allah Dad',
      dor: new Date().toLocaleDateString(),
      session: 'Fall',
      pCellNumber: '03125588965',
      address: 'Gulbarg D1',
      city: 'Lahore',
      comments: '...',
      status: 'on Roll',
      actions: 2500,
      doa: new Date().toLocaleDateString(),
      nationality: 'Pakistan',
      gender: 'Male',
      studentNIC: '366036363638',
      studentDOB: new Date().toLocaleDateString(),
      fCode: 20000,
      ops: 'ASD1234-434FF-23FF-ASD2322',
      pCNIC: '36636-36365625-1',
      area: 'Gulbarg',
      email: 'umer@gmail.com',
      leavingDate: '',
      createdDate: new Date().toLocaleDateString(),
      createdBy: 'Umer Ibrahim'
    },
    {
      key: '2',
      id: 656620,
      regNo: '2569786466',
      cName: 'Play Group (Blue)',
      studentName: 'Muhammad Amir',
      parentName: 'Muhammd Nadeem',
      dor: new Date().toLocaleDateString(),
      session: 'Spring',
      pCellNumber: '03125566995',
      address: '112 G-block',
      city: 'Vehari',
      comments: '...',
      status: 'on Roll',
      actions: 2500,
      doa: new Date().toLocaleDateString(),
      nationality: 'Pakistan',
      gender: 'Male',
      studentNIC: '366036363638',
      studentDOB: new Date().toLocaleDateString(),
      fCode: 20000,
      ops: 'ASD1234-434FF-23FF-ASD8622',
      pCNIC: '36636-8989655-1',
      area: 'B-Block',
      email: 'ali@gmail.com',
      leavingDate: '',
      createdDate: new Date().toLocaleDateString(),
      createdBy: 'Umer Ibrahim',
    },
    {
      key: '3',
      id: 52000,
      regNo: '2566396544',
      cName: 'Play Group (Blue)',
      studentName: 'Ali',
      parentName: 'Ali Khan',
      dor: new Date().toLocaleDateString(),
      session: 'Fall',
      pCellNumber: '03256699885',
      address: 'RA Street',
      city: 'Vehari',
      comments: 'Walton',
      status: 'Left',
      actions: 2500,
      doa: new Date().toLocaleDateString(),
      nationality: 'India',
      gender: 'Male',
      studentNIC: '366036363638',
      studentDOB: new Date().toLocaleDateString(),
      fCode: 20000,
      ops: 'ASD1234-434FF-23FF-78798745',
      pCNIC: '36636-878545-1',
      area: 'Garden',
      email: 'umer@gmail.com',
      leavingDate: new Date().toLocaleDateString(),
      createdDate: new Date().toLocaleDateString(),
      createdBy: 'Umer Ibrahim',
    }
];
