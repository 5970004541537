import { Form, Input, DatePicker, Collapse } from "antd";
import {  IdcardOutlined, MobileOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import RegisteredStudentList from '../SharedComponents/Tables/RegistrationTable';
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
export default function RegisteredAdmissionList(props: any) {
    return (
        <>
            <h4 style={{ textAlign: "center" }}>Registered Admission List</h4>
            <div className='container-fluid workingArea p-2'>
                <Collapse accordion className="emphasisColor" defaultActiveKey={['1']}>
                    <Panel header={<h5 className="white">Search Panel</h5>} key="1">
                        <div className='row'>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Student Name"
                                    label="Student Name"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Student Name" suffix={'SN'} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Registration Number"
                                    label="Registration Number"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Registration Number" suffix={<DeploymentUnitOutlined />} />
                                </Form.Item>

                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Cell Number"
                                    label="Parent Cell Number"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Parent Cell Number" suffix={<MobileOutlined />} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Parent CNIC"
                                    label="Parent CNIC"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Student Name" suffix={<IdcardOutlined />} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Student CNIC/B-Form"
                                    label="Student CNIC / B-Form"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Student CNIC / Form-B" suffix={<IdcardOutlined />} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-2 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Address"
                                    label="Home Address"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                    <Input className="allTextInput" placeholder="Home Address" suffix={<IdcardOutlined />} />
                                </Form.Item>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-6 col-12'>
                                <Form.Item
                                    name="Admission Date"
                                    label="Admission Date"
                                    rules={[{ type: 'string', min: 20, max: 100, warningOnly: true }]} >
                                        <RangePicker className='allTextInput' />
                                </Form.Item>
                            </div>
                            
                        </div>
                    </Panel>
                </Collapse>
                <RegisteredStudentList selectionAllow={props.selectionAllow} />
            </div></>
    )

}