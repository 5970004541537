import { Route, Routes } from "react-router";
import MainLayout from "./Components/MainComponents/Layouts/MainLayout/MainLayout";
import Dashboard from "./Modules/Dashboard/Dashboard";
import NewAdmission from "./Modules/Student/NewAdmission/NewAdmission";

import NewRegistration from "./Modules/Student/NewRegistration/NewRegistration";
import "./GlobalCSS/mediaQueries.css";
import "./GlobalCSS/Global.css";
import "./GlobalCSS/Bootstrap.css";
import NoRoute from "./Components/MainComponents/GlobalCustomUI/NoRoute/NoRoute";
import RegisteredAdmission from "./Modules/Student/RegisteredAdmissionsList/RegisteredAdmissionList";
import StudentsList from "./Modules/Student/StudentsList/StudentsList";
import LeavingForm from "./Modules/Student/OtherForms/LeavingForm";
import FeeHead from "./Modules/Settings/Fee/FeeHeads/FeeHeads";
import CreateFeeTamplate from "./Modules/Settings/Fee/FeeTemplates/CreateFeeTamplates/CreateFeeTamplates";
import FeeTamplatesList from "./Modules/Settings/Fee/FeeTemplates/ListFeeTamplates/FeeTamplatesList";
import Fee from "./Modules/Settings/Fee/Fee";
import ResourcesMaterialAdmin from "./Modules/ResourcesMaterial/ResourcesMaterialAdmin";
import ResourcesMaterialAcademic from "./Modules/ResourcesMaterial/ResourcesMaterialAcademic";

function App() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/student/admission" element={<NewAdmission />} />
        <Route path="/student/registration" element={<NewRegistration />} />
        <Route path="/student/reg_list" element={<RegisteredAdmission />} />
        <Route path="/student/students" element={<StudentsList />} />
        <Route path="/student/leavingform" element={<LeavingForm />} />
        <Route path="/settings/fee" element={<Fee />} />
        <Route path="/settings/fee/fee_head" element={<FeeHead />} />
        <Route
          path="/settings/fee/tamplate/create_template"
          element={<CreateFeeTamplate />}
        />
        <Route
          path="/settings/fee/tamplates/list_fee_templates"
          element={<FeeTamplatesList />}
        />
        <Route
          path="/resourcesmaterial/resourcesmaterialadmin"
          element={<ResourcesMaterialAdmin />}
        />
        <Route
          path="/resourcesmaterial/resourcesmaterialacademic"
          element={<ResourcesMaterialAcademic />}
        />
        <Route
          path="*"
          element={
            <NoRoute
              statusCode="403"
              title="Page Not found"
              subtitle="Provide wrong path please check your web address"
              gotoRoute="/"
            />
          }
        />
      </Routes>
    </MainLayout>
  );
}

export default App;
