import { Select, message, Form, Switch } from "antd";
import HeadList from "./HeadList/TamplateList";

const { Option } = Select;

export default function FeeTamplatesList(props: any) {
    const [form] = Form.useForm();
    const onFinish = () => {
        message.success('Submit success!');
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <div className="container-fluid p-2 workingArea" >
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                        <Form.Item
                            name="Please Select Session"
                            label="Please Select Session"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select placeholder="Select Sesssion" className="allTextInput"  >
                                <Option value={1}>2020-2021</Option>
                                <Option value={2}>2021-2022</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                        <Form.Item
                            name="Select Class"
                            label="Select Class"
                            rules={[{ required: true }, { type: 'number', warningOnly: true }]}>
                            <Select placeholder="Select Class" className="allTextInput"  >
                                <Option value={1}>6A</Option>
                                <Option value={2}>7A</Option>
                                <Option value={3}>8A</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-6 col-12">
                        <Form.Item
                            name="Allow Disable Tamplates"
                            label="Allow Disable Tamplates">
                                <Switch className="m-3"></Switch>
                        </Form.Item>
                    </div>
                </div>
                <HeadList />
            </div>
        </Form>
    )

}
